import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import TabLoader from '../../Common/TabLoader';
import { retrieveGuests } from '../../../actions/OrgAction';
import OverlayPlaceholder from '../../Common/OverlayPlaceholder';

const AddGuest = ({ excluded = [], onSubmit, ownerId }) => {

    const dispatch = useDispatch();

    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [searched, setSearched] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState('');

    const handleSubmit = () => {
        onSubmit(selected);
    }

    const handleSelect = user => {
        setSelected(
            selected => [...selected, user]
        )
    }

    const handleRemove = objectId => {
        setSelected(
            selected => selected.filter(
                user => user.objectId != objectId
            )
        )
    }

    useEffect(() => {

        setLoading(true);

        dispatch(retrieveGuests(ownerId))
            .then(guests => {
                setOptions(guests || []);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }, []);

    useEffect(() => {

        const selectedIds = selected.map(user => user.objectId);
        const excludedIds = excluded.map(user => user.objectId);

        const filterIds = [
            ...selectedIds,
            ...excludedIds,
        ];

        const filtered = options.length > 0 ? options.filter(
            user => !filterIds.includes(user.objectId)
        ) : [];

        setFiltered(filtered);
    }, [selected, excluded, options]);

    useEffect(() => {

        const searched = filtered.filter(
            user => (user.firstNameLower || '').startsWith(searchKey.toLowerCase()) ||
            (user.lastNameLower || '').startsWith(searchKey.toLowerCase())
        );

        setSearched(searched);

    }, [filtered, searchKey]);

    const list = useMemo(() => {
        return searchKey ? searched : filtered;
    }, [filtered, searched, searchKey]);

    return (
        <>
            <div className="modal-body py-0">
                <div className="py-3">
                    <div style={{ width: "100%" }}>
                        <div className="Tab-Search-Container m-0">
                            <i className="fas fa-search"></i>
                            <form autocomplete="off" onSubmit={() => {}}>
                                <input
                                    onChange={e => setSearchKey(e.target.value)}
                                    placeholder="Search Guests"
                                    value={searchKey}
                                    type="search"
                                    id="search"
                                    />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-body py-0" style={{ height: '100%', overflowY: 'auto' }}>
                <div className="mycard-1 py-0">
                    <div className="list-group">
                        {list.map(user =>
                            <Item
                                key={user.objectId}
                                user={user}
                                onSelect={user => handleSelect(user)}
                            />
                        )}
                        {list.length === 0 && (
                            <OverlayPlaceholder
                                show={!isLoading}
                                text={"No Guests found"}
                            />
                        )}
                    </div>
                </div>
                <TabLoader isLoading={isLoading} />
            </div>
            <div className="modal-footer py-0">
                <div style={{ display: "block" }}>
                {selected.map(user => 
                    <Chips key={user.objectId}
                        user={user}
                        onRemove={objectId => handleRemove(objectId)} />
                )}
                </div>
                <button
                    type="button"
                    className="btn btn-hubchart my-3"
                    disabled={!selected.length}
                    onClick={e => handleSubmit()}>
                    Add guest(s)
                </button>
            </div>
        </>
    )
}

const Chips = ({ user = {}, onRemove }) => {

    const { displayName, firstName, picture } = user;

    const imgUrl = picture ? picture.url : require("../../../assets/images/default.png");

    return (
        <div className="chips">
            <img src={imgUrl} className="chip-img" alt={displayName} />
            <span>{firstName}</span>
            <i onClick={e => onRemove(user.objectId)} className="close-chip far fa-times-circle"></i>
        </div>
    )
}

const Item = ({ user = {}, onSelect }) => {

    const { displayName, picture } = user;

    const imgUrl = picture ? picture.url : require("../../../assets/images/default.png");

    return (
        <div className="border-item layout-item">
            <img src={imgUrl}
                alt=""
                className="profile-image rounded-circle mr-2"
                style={{ width: "30px", height: "30px" }} />
            <p className="name m-0">{displayName}</p>
            <button onClick={e => onSelect(user)} className="btn btn-primary btn-sm ml-auto">
                Select
            </button>
        </div>
    )
}

export default AddGuest;
