import React, { useEffect, useMemo, useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Parse from "parse";
import LoadingSpinner from "../../../components/Conversation/Bubble/LoadingSpinner";
import Modal from "../../Common/Modal";
import OverlayLoader from "../../Common/OverlayLoader";
import Member from "./Member";
import { initializePubNub, createActionItems } from "../../../actions/ThreadAction";
import Switch from "react-switch";

const AlertModal = ({
  isOpen,
  onClose,
  options = [],
  onSubmit,
  guests = [],
  threadId = "",
  threadName = "",
  emptyAttentionFromHubOrGroup = false,
  onSendAfterAssign,
  setAsUrgent = () => {},
  isUrgent = false,
  setAsTask = () => {},
  isTask = false,
  message = "",
  bypassList = null,
}) => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searched, setSearched] = useState([]);
  const [searchedGuests, setSearchedGuests] = useState([]);
  const [sortedList, setSortedList] = useState([]);

  const { isSending } = useSelector(mySelector, shallowEqual);

  const selectedIds = useMemo(() => {
    return selected.map((user) => user.objectId);
  }, [selected]);

  const handleSelect = (user) => {
    setSelected((selected) => [...selected, user]); // [...selected, user]
  };

  const handleRemove = (objectId) => {
    setSelected((selected) => selected.filter((user) => user.objectId != objectId));
  };

  const handleSubmit = () => {
    onSubmit(selected);
  };

  const onSubmitAfterAssign = async () => {
    if (isTask && selected.length > 0) {
      const data = {
        text: message,
        assignedTo: selected[0],
        threadName,
        threadId,
      };
      const actionItem = await dispatch(createActionItems(threadId, data));
      onSendAfterAssign(selected, actionItem);
      setSelected([]);
    } else {
      onSendAfterAssign(selected);
      setSelected([]);
    }
  };

  useEffect(() => {
    const searched = options.filter(
      (user) =>
        (user.firstName || "").toLowerCase().startsWith(searchKey.toLowerCase()) ||
        (user.lastName || "").toLowerCase().startsWith(searchKey.toLowerCase())
    );
    setSearched(searched);
  }, [searchKey, options]);

  useEffect(() => {
    const searched = guests.filter(
      (user) =>
        (user.firstName || "").startsWith(searchKey.toLowerCase()) ||
        (user.lastName || "").startsWith(searchKey.toLowerCase())
    );
    setSearchedGuests(searched);
  }, [searchKey, guests]);

  useEffect(() => {
    if (!isOpen) return;
    inputRef.current.focus();
  }, [isOpen]);

  const list = useMemo(() => {
    return searchKey ? searched : options;
  }, [options, searched, searchKey]);

  const listGuest = useMemo(() => {
    return searchKey ? searchedGuests : guests;
  }, [guests, searchedGuests, searchKey]);

  useEffect(() => {
    if (list) {
      if (!bypassList) {
        initializePubNub().then((pubnub) => {
          if (pubnub) {
            pubnub.hereNow({ channels: [`group_${threadId}`] }, function(status, response) {
              if (response) {
                const occupants = response.channels[`group_${threadId}`].occupants.map((occupant) => occupant.uuid);
                const newList = list.filter((item) => occupants.includes(item.objectId));
                const activeList = newList.map((item) => ({
                  ...item,
                  isActiveNow: true,
                }));
                const nonActiveList = list
                  .filter((item) => !occupants.includes(item.objectId))
                  .sort((a, b) => new Date((b.lastDateActive || {}).iso) - new Date((a.lastDateActive || {}).iso));
                const sortedList = [...activeList, ...nonActiveList].filter(
                  (item) => item.objectId !== Parse.User.current().id
                );
                setSortedList(sortedList);
              }
            });
          }
        });
      } else {
        const occupants = bypassList.channels[`group_${threadId}`].occupants.map((occupant) => occupant.uuid);
        const newList = list.filter((item) => occupants.includes(item.objectId));
        const activeList = newList.map((item) => ({
          ...item,
          isActiveNow: true,
        }));
        const nonActiveList = list
          .filter((item) => !occupants.includes(item.objectId))
          .sort((a, b) => new Date((b.lastDateActive || {}).iso) - new Date((a.lastDateActive || {}).iso));
        const sortedList = [...activeList, ...nonActiveList].filter(
          (item) => item.objectId !== Parse.User.current().id
        );
        setSortedList(sortedList);
      }
    }
  }, [list, bypassList, threadId]);

  return (
    <Modal shown={isOpen} onHide={onClose}>
      <ModalHeader />

      <ModalBody>
        <div className="Tab-Search-Container m-0">
          <i className="fas fa-search"></i>
          <form autocomplete="off" onSubmit={() => {}}>
            <input
              ref={inputRef}
              onChange={(e) => setSearchKey(e.target.value)}
              placeholder="Search Members"
              value={searchKey}
              type="search"
              id="search"
            />
          </form>
        </div>
      </ModalBody>
      <ModalBody style={{ height: "100%", overflowY: "auto", padding: "0" }}>
        {/* <div
          style={{ margin: "0px 0px 10px 10px", cursor: "pointer" }}
          onClick={() => setAsUrgent(!isUrgent)}
        >
          <Switch
            disabled={false}
            checked={isUrgent || false}
            onColor="#b6e0ff"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={30}
            className="react-switch ml-1 shift-down-4"
            id="material-switch"
          />
          <span style={{ marginLeft: 5 }}>Is Urgent?</span>
        </div>
        <div
          style={{ margin: "0px 0px 10px 10px", cursor: "pointer" }}
          onClick={() => setAsTask(!isTask)}
        >
          <Switch
            disabled={false}
            checked={isTask || false}
            onColor="#b6e0ff"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={30}
            className="react-switch ml-1 shift-down-4"
            id="material-switch"
          />
          <span style={{ marginLeft: 5 }}>Save as new task</span>
        </div> */}
        <div className="card-body px-3" style={{ padding: "0" }}>
          <label className="text-primary">Members</label>
          {sortedList.map((user) => (
            <Member
              isSelected={selectedIds.includes(user.objectId)}
              onSelect={(user) => handleSelect(user)}
              onRemove={(objectId) => handleRemove(objectId)}
              user={user}
            />
          ))}
          {listGuest.length ? (
            <>
              <hr />
              <label className="text-primary">Guests</label>
              {listGuest.map((user) => (
                <Member
                  isSelected={selectedIds.includes(user.objectId)}
                  onSelect={(user) => handleSelect(user)}
                  onRemove={(objectId) => handleRemove(objectId)}
                  user={user}
                />
              ))}
            </>
          ) : (
            ""
          )}
        </div>
        <OverlayLoader isLoading={loading} />
        {/* <LoadingSpinner isLoading={isDisabled} /> */}
      </ModalBody>
      <ModalFooter>
        {!emptyAttentionFromHubOrGroup && (
          <button className="btn btn-primary" disabled={!selected.length} onClick={handleSubmit}>
            <i className="fas fa-check mr-1"></i>
            Send
          </button>
        )}
        {emptyAttentionFromHubOrGroup && (
          <button className="btn btn-primary" disabled={!selected.length || isSending} onClick={onSubmitAfterAssign}>
            <i className="fas fa-check mr-1"></i>
            Done
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

const ModalHeader = () => {
  return (
    <div className="modal-header">
      <h5 className="text-warning mb-0" style={{ fontSize: "1.25rem" }}>
        <i className="fas fa-at mr-1"></i>
        Send alert to
      </h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

const ModalBody = ({ children, style = {} }) => {
  const styles = {
    ...style,
  };

  return (
    <div className="modal-body" style={styles}>
      {children}
    </div>
  );
};

const ModalFooter = ({ children }) => {
  return <div className="modal-footer">{children}</div>;
};

const mySelector = (state) => ({
  isSending: state.thread.isSending,
});

export default AlertModal;
