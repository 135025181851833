import React, { useEffect, useMemo } from "react";
import OverlayLoader from "../../Common/OverlayLoader";
import { getContactInformation } from "../../../actions/ContactAction";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import moment from "moment";
import "./index.css";

const imageUrl = require("../../../assets/images/default.png");

const ContactProfile = ({ contactId }) => {
  const dispatch = useDispatch();

  const { contact_profile, isLoadingContact } = useSelector(
    (state) => ({
      isLoadingContact: state.contact.isLoadingContact,
      contact_profile: state.contact.contact_profile,
    }),
    shallowEqual
  );

  const formatDate = (str) => {
    return moment(str).format("MMM DD, YYYY");
  };

  const dateString = useMemo(() => {
    return contact_profile.lastDateActive ? formatDate(contact_profile.lastDateActive.iso) : "";
  }, [contact_profile.lastDateActive]);

  useEffect(() => {
    if (contactId) {
      getContactInformation(contactId)(dispatch);
    }
  }, [contactId]);

  return (
    <div>
      <div className="col contact-profile-container">
        {/* Contact banner */}
        <div class="contact-profile-banner mb-2">
          <div
            className="blur-container"
            style={{
              backgroundImage: `url(${(contact_profile.picture || {}).url})`,
            }}
          />
          <img
            src={(contact_profile.picture || {}).url || imageUrl}
            className="rounded-circle img-cls img-centered"
            alt="contact-profile-image"
          />
          <span className="contact-profile-name">{`${contact_profile.displayName ||
            "No name"}, ${contact_profile.title || "No title"}`}</span>
          <span className="contact-profile-description">{`${contact_profile.designation ||
            "No designation"}, ${contact_profile.organization || "No organization"}`}</span>
        </div>
        {/* Buttons */}
        <div className="card d-flex flex-row justify-content-around p-2 shadow-sm mb-4">
          <div className="button-container">
            <button className="btn btn-outline-primary">
              <i className="fas fa-fw fa-comment"></i>
            </button>
          </div>
          <div className="button-container">
            <button className="btn btn-outline-primary">
              <i className="fas fa-fw fa-phone-alt"></i>
            </button>
          </div>
          <div className="button-container">
            <button className="btn btn-outline-primary">
              <i className="fas fa-fw fa-video"></i>
            </button>
          </div>
        </div>
        <label className="text-primary mb-2">DETAILS</label>
        <div
          className="card p-3 shadow-sm mb-4"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gridGap: "0.5rem",
          }}
        >
          <div className="text-primary">
            <i className="fas fa-clock mr-1"></i>
            Last Active Date: {dateString}
          </div>
          <div className="text-primary">
            <i className="fas fa-building mr-1"></i>
            Work Place Name: {contact_profile.workPlace}
          </div>
          <div className="text-primary">
            <i className="fas fa-briefcase mr-1"></i>
            Work Place Type: {contact_profile.workPlaceType}
          </div>
          <div className="text-primary">
            <i className="fas fa-user-md mr-1"></i>
            Profession: {contact_profile.profession}
          </div>
        </div>
        <label className="text-primary mb-2">OTHERS</label>
        <div className="d-flex flex-column">
          <button className="btn btn-outline-danger d-flex justify-content-between align-items-center mb-2">
            <span>
              <i className="fas fa-user-friends mr-1"></i>
              Remove from Contacts
            </span>
            <i className="fas fa-arrow-right mr-1"></i>
          </button>
          <button className="btn btn-outline-danger d-flex justify-content-between align-items-center mb-2">
            <span>
              <i className="fas fa-user-friends mr-1"></i>
              Block this User
            </span>
            <i className="fas fa-arrow-right mr-1"></i>
          </button>
        </div>
      </div>
      <OverlayLoader isLoading={isLoadingContact} />
    </div>
  );
};

export default ContactProfile;
