import React, { useCallback, useState, Fragment } from "react";
import ImageLoad from "./ImageLoad";
import ImagePlaceholder from "../../Common/ImagePlaceholder";
import { showReplyPreview } from "../../../actions/ThreadAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import DOMPurify from "dompurify";

const ReplyTo = ({ replyTo = {} }) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);

  const { fileType } = replyTo;

  const detectType = (type = "") => {
    const typeRegex = {
      image: /(gif|jpe?g|tiff?|png|webp|bmp)$/i,
      video: /(mov|avi|wmv|flv|3gp|mp4|mpg)$/i,
      audio: /(mp3|wav)$/i,
      pdf: /pdf/i,
    };

    if (typeRegex.image.test(type)) return "image";
    if (typeRegex.video.test(type)) return "video";
    if (typeRegex.audio.test(type)) return "audio";
    if (typeRegex.pdf.test(type)) return "pdf";

    return "";
  };

  const getFileText = (url, type = "") => {
    if (!url) return "";

    return `${type} message`;
  };

  const handleOpenFile = useCallback(() => {
    if (!fileType) return;

    dispatch(showReplyPreview(replyTo));
  }, [fileType, replyTo]);

  const type = detectType(fileType);

  const thumbUrl = type === "image" ? replyTo.thumbUrl || replyTo.fileUrl : replyTo.thumbUrl;

  const fileText = getFileText(fileType, type);

  let today = moment(new Date());
  let dateSend = replyTo.originalMessageDate ? 
    moment((replyTo.originalMessageDate || {}).iso) : null;
  let dateString;
  if (dateSend) {
    if (moment(dateSend).isSame(today, "day")) {
      dateString = "Today, " + moment(dateSend).format("LT");
    } else if (dateSend.isSame(moment(), "year")) {
      dateString = moment(dateSend).format("MMM D, h:mm A");
    } else {
      dateString = moment(dateSend).format("MMM D, YYYY");
    }
  }
  

  // const dateString = replyTo.originalMessageDate
  //   ? moment(replyTo.originalMessageDate.iso).format("MMM D, YYYY, h:mm A")
  //   : "";

  const handleSanitizeText = (text) => {
    // Remove extra threadID from thread source
    const cleanHTML = DOMPurify.sanitize(text);
    return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
  };

  const handleSanitizeRemoveHtml = (text) => {
    const cleanHTML = DOMPurify.sanitize(text);
    const tempDiv = document.createElement("span");
    tempDiv.innerHTML = cleanHTML;
    return tempDiv.innerText;
  };

  const handleTextDisplay = (text = "", isViewMore) => {
    const textLength = text.length;

    const lines = text.split(/\r\n|\r|\n/).length;
    const lineSlice = lines > 10 ? lines - 10 : 0;
    const linesArray = text.split(/\r?\n/);
    const remainingLines = linesArray.slice(0, -lineSlice);
    const textSlice = remainingLines.join("\n");

    if (textLength >= 500) {
      return handleSanitizeText(isExpanded ? text : `${text.substr(0, 500)}...`);
    }

    if (isViewMore) {
      return handleSanitizeText(isExpanded ? text : `${textSlice}...`);
    }
    return handleSanitizeText(lines <= 10 ? text : `${textSlice}...`);
  };

  const element = document.getElementById(`text_${replyTo.messageId}`);
  const lineHeight = element ? parseFloat(getComputedStyle(element).lineHeight) : 0;
  const line = element ? element.offsetHeight / lineHeight : 0;
  const moreLines = line > 14;
  // const moreLines = element ? element.offsetHeight >= 181 : false;

  // let lines = (replyTo.text || "").split(/\r\n|\r|\n|<br\s*\/?>/).length;
  // let letters = (replyTo.text || "").length;

  return (
    <div
      className="bg-gray-100 p-2 mb-2"
      style={{
        fontSize: "12px",
        position: "relative",
        minWidth: "0",
        marginLeft: "-18px",
        marginRight: "-18px",
        marginTop: "-7px",
      }}
    >
      <div className="d-flex">
        <i className="fas fa-reply fa-rotate-180 mr-1 text-gray-800 mr-2" style={{ alignSelf: "center" }}></i>

        {thumbUrl ? (
          <div className="mr-2" onClick={(e) => handleOpenFile()}>
            <ImageLoad openLightbox={() => {}} imageURL={thumbUrl} />
          </div>
        ) : (
          ""
        )}

        {fileType && !thumbUrl ? (
          <div className="mr-2">
            <ImagePlaceholder type={type} />
          </div>
        ) : (
          ""
        )}

        <div
          className="w-100 mr-2"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat( auto-fill, 1fr );",
          }}
        >
          <div style={{ minWidth: "0" }}>
            <div className="font-weight-bold">
              <span>{replyTo.sender}</span>
            </div>
            {/* {!isExpanded && ( */}
            <span
              id={`text_${replyTo.messageId}`}
              className={`bubble-replyTo text-gray-800 mb-0 ${!isExpanded ? "replyTo-limited-text" : ""}`}
              style={{ fontStyle: "italic" }}
            >
              {handleSanitizeText(replyTo.text)}
            </span>
            {/* )} */}
            {/* {isExpanded && (
              <span className="bubble-replyTo text-gray-800 mb-0" style={{ fontStyle: "italic" }}>
                {handleSanitizeText(replyTo.text)}
              </span>
            )} */}
            {moreLines && (
              <Fragment>
                {!isExpanded && (
                  <div align="right">
                    <span
                      className="message-text-content"
                      style={{ color: "#2193f3", cursor: "pointer" }}
                      onClick={() => setIsExpanded(true)}
                    >
                      Show more
                    </span>
                  </div>
                )}
                {isExpanded && (
                  <div align="right">
                    <span
                      className="message-text-content"
                      style={{ color: "#2193f3", cursor: "pointer" }}
                      onClick={() => setIsExpanded(false)}
                    >
                      Show less
                    </span>
                  </div>
                )}
              </Fragment>
            )}
            {/* {replyTo.text ? (
              <p className="text-gray-800 mb-0" style={{ fontStyle: "italic" }}>
                {handleTextDisplay(replyTo.text)}
              </p>
            ) : (
              ""
            )} */}

            {!replyTo.text && fileText ? <p className="text-capitalize mb-0">{fileText}</p> : ""}
            <div>{dateString ? <p className="replyTo-date text-gray-600 text-right">{dateString}</p> : ""}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyTo;
