import React, { Component } from 'react';
import { parseAction } from "../../../actions/REST";
import config from '../../../config';
import Notify from '../../Common/Notify';
import Dropbox from './Dropbox';
import Spinner from '../../Common/Spinner';
import '../../Conversation/Setting/Setting.css';
import './Sidebar.css';
const grid = 8;
const $ = window.$;

export class SideBar extends Component {

  constructor() {
    super();

    this.state = {
      openGallery: false,
      activeGallery: "",
      setIsDragging: false,
      isloading: false,
    };

    this.closeNav = this.closeNav.bind(this);
  }

  openNav(type) {
    this.setState({
      openGallery: true,
      activeGallery: type
    })
  }

  closeNav() {
    this.setState({
      openGallery: false,
    })
  }

  openSideBar() {
    this.props.onDocumentOpen();
  }

  closeSideBar() {
    this.props.onDocumentClose();
  }

  removeFilter() {
    this.props.onFilter('');
  }

  setFilter(filter) {
    this.props.onFilter(filter);
  }

  handleDragEnd = (e, label) => {
    e.preventDefault();
    console.log("drop on :", label);
    this.categorizeFile(label);
  }

  categorizeFile(label) {
    this.setState({ isloading: true });
    const method = "post";
    const url = config.BASE_URL + "/parse/functions/categorizeDocument";
    const data = {
      attachmentId: this.props.attachmentId,
      circleId: this.props.threadId,
      emrType: label,
    };

    return parseAction(method, url, data, { "Content-Type": "application/json", })
      .then((response) => {
        Notify.success(response.result.message);
        this.setState({ isloading: false });
      })
      .catch((error) => {
        Notify.error("Could not categorize file.");
        console.error(error);
        this.setState({ isloading: false });
      });
  }

  handleDragHover(hover, index) {
    const element = document.getElementById(index);
    element.style.background = hover ? "#d3d3d3" : "#fff";
  }

  render() {

    const dropdownBaseStyle = {
      height: "100%",
      zIndex: 0,
      outline: "none"
    }

    const activeStyle = {
      display: "block",
      border: "2px dashed green"
    }

    const { documentIsOpen } = this.props;

    let containerWidth;

    if (documentIsOpen) {
      containerWidth = '300px';
    }
    else {
      containerWidth = '65px';
    }

    const {filter, links} = this.props;

    return (
      <div className="Document-Panel" ref="documentPanel">
        <div className="Document-Container" ref="documentContainer" style={{ width: containerWidth }}>
          <div className="Save-File-Container">
            {documentIsOpen ?
              <div style={{ padding: '10px 0px 0px 20px' }}>
                <i 
                  className="fa fa-times-circle animate-fade" 
                  onClick={this.closeSideBar.bind(this)}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                  }}></i>
                <span className="hc-primary-text animate-fade" style={{ fontSize: "1.2rem", fontWeight: 'bold' }}>
                  DOCUMENT HUB
                </span>
              </div>
              :
              <button onClick={this.openSideBar.bind(this)} className="btn btn-hubchart btn-hubchart-primary animate-fade" style={{ height: '100%', fontSize: '12px', fontWeight: 'bold', boxShadow: '-2px 2px 8px #888888' }}>
                DOC HUB
              </button>
            }
          </div>
          <div className="setting-container" style={{ textAlign: "left", top: "50px" }}>
            <div style={{ position: 'relative' }}>
              <div className="detail-padding">
                {this.props.isTagging &&
                  <div className="file-details animate-fade breadcrumb"
                    style={{
                      padding: "10px",
                      backgroundColor: "#ADD8E6", 
                      color: "#2194f3",
                    }}
                  >
                    {this.state.isloading ? 
                      <div style={{ height: "50px", width:"100%" }} align="center">
                        <Spinner top="50" />
                      </div>
                      :
                      <>
                        {documentIsOpen && (
                          <>
                            <span className="nowrap" style={{textAlign:"center"}}>
                              Drag n Drop files on designated hub
                            </span><br />
                          </>
                        )}
                        {/* <span className="nowrap">(Trains AI for file sorting)</span> */}
                        <p className="" style={{ textAlign: "center", width: "100%", margin: "0px" }}>
                          <i className="fas fa-arrow-down mr-1"></i>
                          <i className="fas fa-arrow-down mr-1"></i>
                          <i className="fas fa-arrow-down mr-1"></i>
                        </p>
                      </>
                    }
                  </div>
                }
              </div>

              <div onClick={() => this.removeFilter()} className={filter === '' ? 'detail-container active' : 'detail-container'}>
                <p className="action">
                  <img data-toggle="tooltip" data-placement="top" data-html="true" title="" data-original-title="All files" style={{ height: "25px", marginRight: "20px" }} src={require('../../../assets/images/papers.png')} />
                  {documentIsOpen && <span className="document-category"> All files </span>}
                </p>
              </div>

              {links.map((link, index) =>
                <Dropbox target={link.dropzoneRef} key={index}>
                  <div
                    id={index}
                    onClick={() => this.setFilter(link.dropzoneRef)} 
                    className={link.dropzoneRef === filter ? "doc-category detail-container active" : "doc-category detail-container"}
                    // onDrop={(e) => this.handleDragEnd(e, link.dropzoneRef)}
                    // onDragOver={(e) => e.preventDefault()}
                  >
                    <p className="action">
                      <img
                        data-toggle="tooltip"
                        data-placement="top"
                        data-html="true"
                        title=""
                        data-original-title={link.label}
                        style={{ height: "25px", marginRight: "20px" }}
                        src={link.imageSrc} />

                      {documentIsOpen ?
                        <span className="document-category">{link.label}</span>
                        : ''
                      }
                    </p>
                  </div>
                </Dropbox>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SideBar;
