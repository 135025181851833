import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import ProfilePic from "../../../Common/ProfilePic";
import moment from "moment";
import DOMPurify from "dompurify";
import Parse from "parse";
import Notify from "../../../Common/Notify";
import OverlayLoader from "../../../Common/OverlayLoader";
import { getActionItemDiscussion, sendDiscussionMessage, completeActionItem } from "../../../../actions/ThreadAction";
import "./DiscussionModal.css";

const $ = window.$;

function DiscussionModal({ isOpen, modalClose = () => {}, item }) {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [desc, setDescription] = useState("");
  const [discussion, setDiscussion] = useState([]);
  const [comment, setComment] = useState("");

  const discussionRef = useRef(null);

  useEffect(() => {
    discussionRef.current.scrollTop = discussionRef.current.scrollHeight;
  }, [discussion]);

  useEffect(() => {
    console.log("item:", item);
    setLoading(true);
    dispatch(getActionItemDiscussion(item.objectId)).then((dis) => {
      setDiscussion(dis.discussion || []);
      setLoading(false);
    });

    const cleanHTML = DOMPurify.sanitize(item.text);
    const tempDiv = document.createElement("span");
    tempDiv.innerHTML = cleanHTML;
    const cleanText = tempDiv.innerText;
    setDescription(cleanText);
  }, []);

  const handleComplete = async () => {
    setLoading(true);

    await dispatch(completeActionItem(item.objectId));
    item.status = "DONE";

    setLoading(false);
  };

  const sendMessage = () => {
    setLoading(true);

    dispatch(sendDiscussionMessage(comment, item.threadId, item.objectId)).then((res) => {
      if (res !== undefined) {
        setDiscussion([
          ...discussion,
          {
            originalMessageDate: { iso: new Date().toISOString() },
            text: comment,
            user: { displayName: res.user.displayName },
          },
        ]);
        setComment("");
      } else {
        Notify.error("User is not a member of this group");
      }
      setLoading(false);
      discussionRef.current.scrollTop = discussionRef.current.scrollHeight;
    });
  };

  var allow_mark_done =
    Parse.User.current().id === item.createdBy.objectId || Parse.User.current().id === (item.assignedTo || {}).objectId
      ? true
      : false;
  var due_date = item.due_date ? new Date(item.due_date.iso) : <span className="none">None</span>;

  return (
    <div className="discussion-modal-container">
      <div className="discussion-container">
        <div className="" style={{ marginLeft: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h5 className="text-primary mb-0">Action Item - Discussion</h5>
            <i className="fas fa-times fa-lg" style={{ float: "right" }} role="button" onClick={() => modalClose()} />
          </div>
        </div>

        <div className="inline-flex p-1 mb-1" style={{ border: "1px solid #d1d3e2", borderRadius: "0.35rem" }}>
          <div className="flex-none">
            <h4 className="">{item.assignedTo.displayName}</h4>
            <div className="task-details">
              <span>Assigned by</span>
              <span>{item.createdBy.displayName}</span>
              <span>Status</span>
              <span>
                <ItemStatus status={item.status} />
              </span>
              <span>Priority</span>
              <span>
                <ItemPriority priorityLevel={item.priorityLevel} />
              </span>
              <span>Due Date</span>
              <span>{moment(due_date).format("l")}</span>
            </div>
          </div>
          <div className="grow">
            <label
              className=""
              style={{
                display: "flex",
                whiteSpace: "pre-line",
                maxHeight: "110px",
                overflowY: "scroll",
              }}
            >
              {desc}
            </label>
          </div>
        </div>

        <div style={{ maxHeight: "40px" }}>
          <label className="mt-2">
            Discussion:&nbsp;
            <span class="badge badge-pill badge-secondary text-xs">{discussion.length}</span>
          </label>
          <button
            className="btn btn-sm btn-outline-primary m-1"
            style={{ float: "right" }}
            type="button"
            onClick={() => handleComplete()}
            disabled={item.status === "DONE" || !allow_mark_done}
          >
            {isLoading ? (
              <div className="spinner-border spinner-border-sm text-primary" role="status">
                <span className="sr-only">...</span>
              </div>
            ) : (
              <>
                <i className="fas fa-check mr-2 btn-outline-success"></i>&nbsp;
                {item.status === "PENDING" ? "Mark as Done" : "COMPLETE"}
              </>
            )}
          </button>
        </div>

        <div className="">
          <div
            ref={discussionRef}
            className="discussion-comment"
            style={{ border: "1px solid #d1d3e2", borderRadius: "0.35rem" }}
          >
            {discussion.map((item, index) => (
              <Comment item={item} key={index} />
            ))}
            {discussion.length === 0 && isLoading && (
              <div style={{ position: "relative", height: "calc(100vh - 380px)" }}>
                <OverlayLoader isLoading={true} />
              </div>
            )}
          </div>
        </div>
        <div className="input-group" style={{ height: "39px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Add Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="button"
              disabled={isLoading}
              onClick={() => sendMessage()}
              style={{
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              {isLoading ? (
                <div className="spinner-border spinner-border-sm text-primary" role="status">
                  <span className="sr-only">...</span>
                </div>
              ) : (
                <>
                  <i className="fas fa-paper-plane m-1"></i> Send
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const Comment = ({ item }) => {
  let today = moment(new Date());
  let dateSend = moment((item.originalMessageDate || {}).iso);
  let themDate;
  if (moment(dateSend).isSame(today, "day")) {
    themDate = "Today, " + moment((item.originalMessageDate || {}).iso).format("LT");
  } else {
    themDate = moment((item.originalMessageDate || {}).iso).format("MMM D, YYYY, h:mm A");
  }

  return (
    <div className="m-0.5">
      <div className="d-flex align-items-center m-1">
        <div style={{ width: "25px", height: "25px" }}>
          <ProfilePic url={""} size="25" />
        </div>
        <div className="comment-bubble m-1 p-2">{item.text}</div>
      </div>
      <div className="comment-sender m-0.1">
        {themDate} &#9679; {item.user.displayName}
      </div>
    </div>
  );
};

const ItemStatus = ({ status = "" }) => {
  const classNames = useMemo(() => {
    switch (status) {
      case "PENDING":
        return "badge badge-warning badge-pill";
      case "DONE":
        return "badge badge-success badge-pill";
      default:
        return "badge badge-light badge-pill";
    }
  }, [status]);

  return <span className={classNames}>{status}</span>;
};

const ItemPriority = ({ priorityLevel = "" }) => {
  let priority = null;
  switch (priorityLevel) {
    case 2:
      priority = (
        <span className="badge badge-pill badge-light priority-flex" style={{ border: "1px solid #04589e" }}>
          <i className="fad fa-angle-double-up fa-fw" style={{ color: "#04589e" }}></i>
          <span>&nbsp;High Priority</span>
        </span>
      );
      break;
    case 1:
      priority = (
        <span className="badge badge-pill badge-light priority-flex" style={{ border: "1px solid #f6c23e" }}>
          <span style={{ color: "#f6c23e" }}> = </span>
          <span>&nbsp;Medium Priority</span>
        </span>
      );
      break;
    case 0:
      priority = (
        <span className="badge badge-pill badge-light priority-flex" style={{ border: "1px solid grey" }}>
          <i class="text-primary fad fa-angle-double-down fa-fw"></i>
          <span>&nbsp;Routine Priority</span>
        </span>
      );
      break;
    default:
      priority = <span className="none">None</span>;
      break;
  }

  return priority;
};

export default DiscussionModal;
