import React, { Component } from "react";
import sort from "fast-sort";
import Item from "../Item";
import ItemSearch from "../ItemSearch";
import _ from "lodash";
import LoadingSpinner from "../../../components/Conversation/Bubble/LoadingSpinner";

export class MessageList extends Component {
  render() {
    let { typeValue, threads, reverse, filterAttention, activeMessageTab, activeUnreadTab, searchMessages } = this.props;
    let tempThread = [...threads];

    // Filter out duplicate thread id's
    const uniqueThread = _.uniqBy(tempThread, "threadId");

    let allSorted;
    let copySorted;

    // Sort by message date
    // if (!reverse) {
      allSorted = uniqueThread; // sort(uniqueThread).desc([(t) => t.messageDate.iso]);
    // } else {
      // allSorted = sort(uniqueThread).asc([(t) => t.messageDate.iso]);
    // }

    // Sort by Pinned, Urgent, Attetion and unread
    // allSorted = [
    //   ...allSorted.filter((t) => t.isPinned), 
    //   ...allSorted.filter((t) => t.hasUnreadUrgent),
    //   ...allSorted.filter((t) => t.hasUnreadAttention),
    //   ...allSorted.filter((t) => !t.isPinned && !t.hasUnreadUrgent && !t.hasUnreadAttention)
    // ];

    if (
      filterAttention &&
      activeUnreadTab !== "attention" &&
      activeUnreadTab !== "urgent" &&
      activeUnreadTab !== "private" &&
      activeUnreadTab !== ""
    ) {
      allSorted = allSorted.filter((thread) => {
        return thread.hasAttention || thread.threadType === "private" || thread.threadType === "adhoc";
      });
    }

    if (typeValue !== "") {
      allSorted = allSorted.filter((thread) => {
        let name = "";
        if (thread.threadType === "private") {
          name = thread.partnerName;
        }
        if (thread.threadType === "group") {
          name = thread.groupName;
        }
        console.log();
        name = name.toLowerCase().includes(typeValue.toLowerCase());
        return name;
      });
    }

    const showPatientBadge = true; // activeMessageTab != "ALL";

    const allItems = allSorted.map((data, i) => {
      // console.log('Here is where latestMessage should show: ', data);
      return (
        <Item
          key={data.threadId}
          data={data}
          type="message"
          setActiveThread={this.props.setActiveThread}
          activeThread={this.props.activeThread}
          history={this.props.history}
          path={this.props.path}
          location={this.props.location}
          match={this.props.match}
          showPatientBadge={showPatientBadge}
        />
      );
    });

    // console.log(searchMessages);
    // const allSearchItems = searchMessages.map((data, i) => {
    //   return (
    //     <ItemSearch
    //       key={data.threadId}
    //       data={data}
    //       type="message"
    //       setActiveThread={this.props.setActiveThread}
    //       activeThread={this.props.activeThread}
    //       history={this.props.history}
    //       path={this.props.path}
    //       location={this.props.location}
    //       match={this.props.match}
    //       showPatientBadge={showPatientBadge}
    //     />
    //   );
    // });

    let containerStyle = {
      border: "0px solid white",
      minHeight: "50px",
      height: "50px",
      borderRadius: "0px",
      padding: "0",
    };

    let styles = {
      position: "absolute",
      width: "100%",
    };

    return (
      <div className="list-group modified-collection" style={styles}>
        {allItems}
        {allItems.length === 0 && <div className="text-empty tab-placeholder">No results found</div>}

        {this.props.isLoadingMore && (
          <a
            key="group-load-more"
            style={containerStyle}
            className="list-group-item list-group-item-action flex-column align-items-start"
          >
            <LoadingSpinner top={"0"} />
          </a>
        )}
      </div>
    );
  }
}

export default MessageList;
