import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  setAttentionedMessage,
  searchMessage,
} from "../../actions/ThreadAction";
import Spinner from "../Common/Spinner";
import moment from "moment";

const ItemSearch = ({ activeThread, type, history, scrollBottom, isSearching }) => {
  const dispatch = useDispatch();

  const {
    searchMessages,
  } = useSelector(mySelector, shallowEqual);
  
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setHasMore(searchMessages.hasMore);
    if (searchMessages.typeValue !== searchTerm) {
      setSearchTerm(searchMessages.typeValue);
      setPage(0);
    }
  }, [searchMessages]); 

  useEffect(() => {
    if (scrollBottom && hasMore) {
      // setIsLoading(true);
      dispatch(searchMessage(searchMessages.typeValue, page + 1, activeThread.threadId))
      .then((res) => {
        //
      });
      setPage(page + 1);
    }

  }, [scrollBottom]);
  

  const openThread = (thread) => {
    dispatch(setAttentionedMessage(thread));
    history.push({
      pathname: "/u/" + thread.threadId,
    });
  };

  return (
    <div
      className="SideTab-messages-results"
    >
    {isSearching ? (
        <div style={{ height: "70px", marginTop: "25px" }} align="center">
            <Spinner top="50" />
        </div>
        ) : (
          <>
            {(searchMessages.messages.length === 0) ? (
              <div className="d-flex" style={{ padding: "15px 45px" }}>
                <i className="fas fa-search fa-2x" style={{margin: "5px"}} ></i>
                <p className="mx-1"> We couldn't find anything matching your search.</p>
              </div>
            ) : (
              ""
            )}
            {searchMessages.messages &&
              searchMessages.messages.map((item) => (
                <Item
                  type={"contacts"}
                  item={item}
                  openThread={(item) => openThread(item, "message")}
                  key={item.objectId}
                  typeValue={searchMessages.typeValue}
                />
              ))}
            {hasMore && (
              <div style={{ height: "40px" }} align="center">
                <Spinner top="50" />
              </div>
            )}
            {(!hasMore && searchMessages.messages.length > 0) && (
              <a className="dropdown-item d-flex flex-row item-result">
                <div className="text-secondary">- End of List -</div>
              </a>
            )}
          </>
        )}
    </div>
  );
};

const Item = ({ type, item = {}, openThread, typeValue }) => {
  let content = {};
  let displayName = item.user.displayName;
  content.main = displayName.toUpperCase();
  
  let today = moment(new Date());
  let dateSend = moment(item.originalMessageDate.iso);
  let messageDate;
  if (dateSend) {
    if (moment(dateSend).isSame(today, "day")) {
      messageDate = moment(dateSend).format("LT");
    } else {
      messageDate = moment(dateSend).format("MM/DD/YY");
    }
  }

  var urlImage = require("../../assets/images/group-default.png");

  const removeHtml = item.text.replace(/<\/?[^>]+(>|$)/g, "");
  const removeSpace = removeHtml.replace(/^\s*[\r\n]/gm, "");
  const lines = removeSpace.split("\n");
  const line_keyword = lines.find(line => line.toLowerCase().includes(typeValue.toLowerCase()));
  let cssText;

  if (line_keyword) {
    let parts = line_keyword.split(new RegExp(`(${typeValue})`, "gi"));
    cssText = parts.map((part, index) =>
      part.toLowerCase() === typeValue.toLowerCase() ? (
        <strong key={index} style={{ color: "black" }}>{part}</strong>
      ) : (
        part
      )
    );
  } else {
    cssText = lines;
  }

  return (
    <a
      className="dropdown-item d-flex flex-row item-result"
      href="#"
      style={{ border: "0px", paddingLeft: "0px" }}
      onMouseDown={(e) => openThread(item, type)}
      title={removeHtml}
    >
      <div className="m-1">
        <img src={urlImage} />
      </div>
      <div style={{maxWidth: "200px", overflow: "hidden"}}>
        <div className="" style={{ margin: "0px", fontWeight: "400" }}>
          {content.main}
        </div>
        <span style={{color: "rgba(153, 153, 153, 1)"}}>
          {cssText}
        </span>
      </div>
      <div className="text-secondary text-xs" style={{ marginLeft: "auto", marginTop: "5%" }}>
        <span>{messageDate}</span>
      </div>
    </a>
  );
};

const mySelector = (state) => ({
    activeMessageTab: state.thread.activeMessageTab,
    activeUnreadTab: state.thread.activeUnreadTab,
    prevOpenedThread: state.thread.prevOpenedThread,
    searchMessages: state.thread.searchMessages,
  });

export default ItemSearch;
