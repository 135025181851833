import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../../../components/Conversation/Bubble/LoadingSpinner";
import Item from "../Item";
import _ from "lodash";
import "./PatientListStyle.css";

const Label = (props) => {
  let cardStyle = {
    background: "white",
    border: "0",
    padding: "10px !important",
    padding: "3px 1.25rem",
  };

  return (
    <a href="#!" className="label-item" onClick={() => props.openLabelNav(props.label)}>
      <div className="card" style={{ border: "none" }}>
        <div className="card-header" style={cardStyle} id={"f" + props.label.objectId + "heading"}>
          <p
            style={{
              boxShadow: "0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)",
              margin: "0",
              padding: "5px 30px 5px 5px",
              position: "relative",
            }}
          >
            {props.label.name}
            <i
              className="fas fa-chevron-right"
              style={{
                position: "absolute",
                right: "13px",
                top: "10px",
                fontSize: "13px",
              }}
            ></i>
          </p>
        </div>
      </div>
    </a>
  );
};

export class PatientList extends Component {
  render() {
    let {
      typeValue,
      patients,
      isSearchingPatient,
      filterPatientResult,
      facilities,
      facilitiesFetchStatus,
      insurance,
      insuranceFetchStatus,
      isLoadingMorePatients,
      isLoadingSearch,
      hasMore = true,
      patientListShown,
      isLoadingPatients,
      insuranceProviders,
      recentSearch,
    } = this.props;

    let containerStyle = {
      border: "0px solid white",
      minHeight: "50px",
      height: "50px",
      borderRadius: "0px",
      padding: "0",
    };

    let cardStyle = {
      background: "white",
      border: "0",
      padding: "10px !important",
    };

    let tempPatients;
    if (isSearchingPatient) {
      tempPatients = [...filterPatientResult];
    } else {
      tempPatients = [...patients];
    }
    const Items = tempPatients.map((data) => {
      return (
        <Item
          isPatient={true}
          key={data.objectId}
          data={data}
          type="chart"
          createThread={this.props.createThread}
          history={this.props.history}
        />
      );
    });

    const recentItems = recentSearch.map((data) => {
      data.objectId = data.circle._id;
      data.circle.objectId = data.circle._id;

      return (
        <Item
          isPatient={true}
          key={data.objectId}
          data={data.circle}
          type="chart"
          createThread={this.props.createThread}
          history={this.props.history}
        />
      );
    });

    if (isSearchingPatient) {
      return (
        <div className="list-group modified-collection">
          {Items}
          {Items.length > 0 && !hasMore ? <span className="text-empty">--- End of list ---</span> : ""}
          {Items.length === 0 ? <span className="text-empty">--- No results found ---</span> : ""}
        </div>
      );
    }

    const facilityLabel = facilities.map((data) => {
      return (
        <Label
          key={data.objectId}
          label={data}
          parentID="facilityAccordion"
          fetchGroups={this.fetchGroups}
          openLabelNav={this.props.openLabelNav}
        />
      );
    });

    const insuranceLabel = insuranceProviders.map((data) => {
      return (
        <Label
          key={data.objectId}
          label={data}
          parentID="insuranceAccordion"
          fetchGroups={this.fetchGroups}
          openLabelNav={this.props.openLabelNav}
        />
      );
    });

    return (
      <div className="list-group modified-collection">
        <div className="accordion" id="accordionPatients">
          {/* <div className="card" style={{border: "none"}}>
                        <div className="card-header" style={cardStyle} id="facilityHeader">
                            <h2 className="mb-0" style={{boxShadow: "0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)"}}>
                                <button style={{paddingLeft: "25px"}} className="btn btn-link" type="button" data-toggle="collapse" data-target="#facilityCollapse" aria-expanded="false" aria-controls="facilityCollapse">
                                List of Facility
                                </button>
                                <i className="fas fa-hospital hc-primary-text list-icon"></i>
                            </h2>
                        </div>

                        <div id="facilityCollapse" className="collapse" aria-labelledby="facilityHeader" data-parent="#accordionPatients">
                        <div className="card-body">
                            {facilitiesFetchStatus === "FETCHING" && <LoadingSpinner top={"0"}/>}
                            <div className="accordion" id="facilityAccordion">
                                {facilityLabel}
                            </div>
                        </div>
                        </div>
                    </div> */}
          <div className="card" style={{ border: "none" }}>
            <div className="card-header" style={cardStyle} id="insuranceHeader">
              <h2
                className="mb-0"
                style={{
                  boxShadow: "0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)",
                  borderRadius: "5px",
                }}
              >
                <button
                  style={{
                    paddingLeft: "25px",
                    borderRadius: "5px",
                  }}
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#insuranceCollapse"
                  aria-expanded="false"
                  aria-controls="insuranceCollapse"
                >
                  List of Insurance
                </button>
                <i className="fas fa-heartbeat hc-primary-text list-icon"></i>
              </h2>
            </div>
            <div
              id="insuranceCollapse"
              className="collapse"
              aria-labelledby="insuranceHeader"
              data-parent="#accordionPatients"
            >
              <div className="card-body">
                {insuranceFetchStatus === "FETCHING" && <LoadingSpinner top={"0"} />}
                <div className="accordion" id="insuranceAccordion">
                  {insuranceLabel}
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{ border: "none" }}>
            <div className="card-header" style={cardStyle} id="allRecentHeader">
              <h2
                className="mb-0"
                style={{
                  boxShadow: "0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)",
                  borderRadius: "5px",
                }}
              >
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#recentPatientCollapse"
                  aria-expanded="true"
                  aria-controls="recentPatientCollapse"
                  style={{ borderRadius: "5px" }}
                >
                  List of all Recent Activity
                </button>
              </h2>
            </div>
            <div
              id="recentPatientCollapse"
              className={patientListShown ? "collapse show" : "collapse"}
              aria-labelledby="recentPatientCollapse"
              data-parent="#accordionPatients"
            >
              <div className="card-body" style={{ padding: "0" }}>
                {recentItems}
                {isLoadingPatients ? <LoadingSpinner top={"0"} /> : ""}
              </div>
            </div>
          </div>
          <div className="card" style={{ border: "none" }}>
            <div className="card-header" style={cardStyle} id="allPatientHeader">
              <h2
                className="mb-0"
                style={{
                  boxShadow: "0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)",
                  borderRadius: "5px",
                }}
              >
                <button
                  // onClick={this.props.fetchAllPatients}
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#allPatientCollapse"
                  aria-expanded="true"
                  aria-controls="allPatientCollapse"
                  style={{ borderRadius: "5px" }}
                >
                  List of all Patient Groups
                </button>
              </h2>
            </div>
            <div
              id="allPatientCollapse"
              // className={patientListShown ? "collapse show" : "collapse"}
              className="collapse show"
              aria-labelledby="allPatientHeader"
              data-parent="#accordionPatients"
            >
              <div className="card-body" style={{ padding: "0" }}>
                {Items}
                {isLoadingPatients || isLoadingMorePatients ? <LoadingSpinner top={"0"} /> : ""}
              </div>
            </div>
          </div>
        </div>
        {/* { Items }
                {this.props.isLoadingMorePatients && <a key="group-load-more" style={containerStyle} className="list-group-item list-group-item-action flex-column align-items-start">
                    <LoadingSpinner top={"0"}/>
                </a>} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  recentSearch: state.patient.recentSearch,
});

export default connect(mapStateToProps, {})(PatientList);
