import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { deleteEvent } from "../../../api/GoogleCalendarApi";
import { parseAction } from "../../../actions/REST";
import { newGroup, inviteMember } from "../../../actions/GroupAction";
import { setActiveTab } from "../../../actions/ThreadAction";
import UpdateConferenceModal from "./UpdateConferenceModal";
import UpdateScheduleModal from "./UpdateScheduleModal";
import ManageEventModal from "../../Pages/ConferenceMain/ManageEventModal";
import ConfirmModal from "./ConfirmModal";
import ConferenceHelper from "../../../helper/conference";
import ForwardModal from "./ForwardConferenceModal";
import TabLoader from "../../Common/TabLoader";
import ConferenceItem from "./ConferenceItem";
import ConferenceNav from "./ConferenceNav/index.js";
import ScheduleItem from "./ScheduleItem";
import RequestItem from "./RequestItem";
import Loader from "../../Common/Loader";
import Notify from "../../Common/Notify";
import util from "../../../helper/util";
import config from "../../../../src/config";
import Parse from "parse";
import {
  getActiveConferenceCall,
  createConferenceCall,
  endConferenceCall,
  getConferenceCalls,
  scheduleConferenceCall,
  getSchedules,
  getMeetings,
  signInToGoogle,
  loadGoogleApi,
  deleteConferenceCall,
} from "../../../actions/ConferenceAction";

function ConferenceContainer({ history }) {
  const [updateScheduleShown, setUpdateScheduleShown] = useState(false);
  const [confirmData, setConfirmText] = useState({ title: "", body: "" });
  const [confirmShown, setConfirmModalShown] = useState(false);
  const [selectedConference, setSelectedConference] = useState(null);
  const [forwardModalShown, setForwardModalShown] = useState(false);
  const [updateModalShown, setUpdateModalShown] = useState(false);
  const [messageToForward, setMessageToForward] = useState({});
  const [loadingMessage, setLoadingMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveCall, setIsActiveCall] = useState(false);

  const {
    conference = {},
    conferences,
    isLoadingConferences,
    isLoadingActiveCall,
    activeConferenceTab,
    schedules,
    telemed_schedules,
    isLoadingSchedules,
    isGoogleSignedIn,
  } = mapStateToProps();

  const roomId = conference.roomId;

  const dispatch = useDispatch();

  // Use componentDidmount lifecycle
  useEffect(componentDidMount, []);

  // Set clear conference to window object
  // so popup window can call it via window.opener.clearConference
  window.clearConference = clearConference;

  function mapStateToProps() {
    return useSelector(
      (state) => ({
        conference: state.conference.activeConference,
        conferences: state.conference.conferences,
        isLoadingConferences: state.conference.isLoadingConferences,
        isLoadingActiveCall: state.conference.isLoadingActiveCall,
        activeConferenceTab: state.conference.activeConferenceTab,
        schedules: state.conference.schedules,
        telemed_schedules: state.conference.telemed_schedules,
        isLoadingSchedules: state.conference.isLoadingSchedules,
        isGoogleSignedIn: state.conference.isGoogleSignedIn,
      }),
      shallowEqual
    );
  }

  function clearConference() {
    dispatch({
      type: "REMOVE_CONFERENCE",
    });
  }

  function componentDidMount() {
    // Retrieve active conference call
    // getActiveConferenceCall()(dispatch);

    // Retrieve history
    getSchedules()(dispatch);
    handleClientLoad();
  }

  function joinConference(roomId) {
    // Set room name
    let room = generateRoomName();

    const conferenceUrl = [
      window.location.origin,
      "/conferencecall",
      "?rid=" + room,
      "&roomId=" + roomId,
      "&hasVideo=false",
    ].join("");

    window.open(conferenceUrl, "Hubchart Conference Call", "status=1,menubar=0");
    // window.location.href = conferenceUrl;
  }

  function endConference(roomId) {
    setLoading(true);
    setLoadingMessage("Ending conference call");

    return endConferenceCall(roomId)(dispatch)
      .then((result) => {
        setLoading(false);

        const content = (
          <span>
            <i className="fas fa-phone fa-phone-end mr-2"></i>
            <strong>Conference call was ended</strong>
          </span>
        );

        Notify.error(content);
      })
      .catch((response) => {
        setLoading(false);
        console.log(response);
        console.error("End conference call failed");

        const content = (
          <span>
            <i className="fas fa-phone fa-phone-end mr-2"></i>
            <strong>Conference call has ended</strong>
          </span>
        );

        Notify.error(content);
        return Promise.reject();
      });
  }

  function setLoading(boolean, message) {
    setIsLoading(boolean);
    setLoadingMessage(message);
  }

  function scheduleConference(participantIds, dateScheduled) {
    // Show loading indicator with message
    setLoading(true, "Setting scheduled conference...");

    const ISOString = dateScheduled.toISOString();

    return scheduleConferenceCall(
      participantIds,
      ISOString
    )(dispatch)
      .then((result) => {
        // Hide loading indicator
        setLoading(false);

        Notify.success("Scheduled conference was set!");
      })
      .catch((response) => {
        // Hide loading indicator
        setLoading(false);

        const { responseJSON } = response;

        if (responseJSON) {
          const { error, code } = responseJSON;

          Notify.error(error);
        } else {
          Notify.error("Something went wrong");
        }

        return Promise.reject();
      });
  }

  function generateRoomName() {
    const date = new Date();
    const timestamp = date.getTime();

    const userId = Parse.User.current().id;

    return [userId, String(timestamp)].join("_");
  }

  function renderCallControls() {
    const { participants } = conference;

    const imageUrl = require("../../../assets/images/default.png");

    const pics = participants.map((p) => {
      const url = p.picture ? p.picture.url : imageUrl;

      return (
        <div key={p.objectId} className="img-conference-wrapper">
          <img src={url} width="35" height="35" />
        </div>
      );
    });

    const names = participants.map((p) => p.firstName).join(", ");

    const currentUser = Parse.User.current();

    const isHost = currentUser.id === conference.host.objectId;

    return (
      <div className="Tab-Row">
        <div className="alert alert-success" align="center">
          <span style={{ fontWeight: "bold" }}>You have a call in progress</span>
          <div className="name conference-participants pt-3" style={{ justifyContent: "center" }}>
            {pics}
          </div>
          <div className="name">with {names}</div>
          <div className="pt-3">
            {isHost ? (
              <button
                style={{ marginBottom: "5px" }}
                className="btn btn-danger btn-join-call mr-2"
                onClick={() => endConference(roomId)}
              >
                <i className="fas fa-phone fa-phone-end mr-1" />
                End call
              </button>
            ) : (
              ""
            )}
            <button
              style={{ marginBottom: "5px" }}
              className="btn btn-success btn-join-call"
              onClick={() => joinConference(roomId)}
            >
              <i className="fa fa-phone mr-1"></i>
              Join call
            </button>
            <button
              style={{ marginBottom: "5px" }}
              className="btn btn-primary btn-join-call"
              onClick={() => handleOpenUpdateModal(conference, true)}
            >
              <i className="fa fa-notes-medical mr-1"></i>
              Edit Notes
            </button>
          </div>
        </div>
      </div>
    );
  }

  function handleOpenUpdateModal(conference, activeCall = false) {
    // Set selected conference
    setSelectedConference(conference);
    setIsActiveCall(activeCall);

    setUpdateModalShown(true);
  }

  function handleOpenUpdateSchedule(conference) {
    // Set selected conference
    setSelectedConference(conference);

    setUpdateScheduleShown(true);
  }

  function handleSetUpdateModal(isUpdated = false) {
    if (isUpdated) {
      getActiveConferenceCall()(dispatch);
    }
  }

  function handleOpenConfirmModal(conference, cData) {
    setConfirmText(cData);
    setSelectedConference(conference);
    setConfirmModalShown(true);
  }

  function handleDeleteSchedule(data) {
    setConfirmModalShown(false);
    if (data) {
      handleDeleteDbSchedule(data.objectId);
      if (isGoogleSignedIn && data.eventId) {
        deleteEvent(data.eventId, (response) => {
          if (response.error) {
            const code_str = response.code === 404 ? "Event ID not found." : "";
            Notify.error(`Failed to delete Telemed schedule. ${code_str}`);
          }
        });
      }
    }
  }

  function handleDeleteDbSchedule(objectId) {
    deleteConferenceCall({ objectId })(dispatch)
      .then((result) => {
        Notify.success("Telemed schedule successfully deleted.");
        getMeetings()(dispatch);
        getSchedules()(dispatch);
      })
      .catch((response) => {
        const { responseJSON } = response;
        if (responseJSON) {
          const { error, code } = responseJSON;
          Notify.error(error);
        } else {
          Notify.error("Something went wrong");
        }
        setLoading(false);
        return Promise.reject();
      });
  }

  function handleMessageGroup(c) {
    const userId = Parse.User.current().id;
    const selContacts = c.participants.map((item) => ({
      objectId: item.objectId,
      username: item.username,
      firstName: item.firstName,
    }));
    const filteredSelContacts = selContacts.filter((item) => item.objectId !== userId);

    if (filteredSelContacts.length === 1) {
      history.push(`/u/${filteredSelContacts[0].objectId}`);
      dispatch(setActiveTab("message"));
    } else {
      createGroup(filteredSelContacts);
    }
  }

  function createGroup(selectedContacts) {
    const name = Parse.User.current().get("firstName");
    const groupName = `${name}, ${selectedContacts.map((item) => item.firstName).join(", ")}`;
    const memberIds = selectedContacts.map((item) => item.objectId);
    const params = { groupName, memberIds };

    setIsLoading(true);

    parseAction(
      "post",
      config.BASE_URL + "/parse/functions/createCircleUnique", //createRegularGroup'
      params,
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        const isNew = res.result.isNew;
        const group = res.result.circle;
        const { objectId } = group;
        if (isNew) {
          newGroup(group)(dispatch);
        }
        dispatch(setActiveTab("group"));
        history.push(`/u/${objectId}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function inviteMembers(contacts, uuid, groupId) {
    const promises = contacts.map((contact) => {
      return inviteMember(contact, uuid)(dispatch);
    });

    Promise.all(promises)
      .then(() => {
        Notify.success("Group successfully created!");
        setIsLoading(false);
        history.push(`/u/${groupId}`);
        dispatch(setActiveTab("group"));
      })
      .catch((res) => {
        const { responseJSON } = res;
        if (responseJSON) {
          const { error } = responseJSON;
          const alertContent = (
            <div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>Failed to add member</div>
              <div style={{ fontSize: "14px" }}>{error}</div>
            </div>
          );
          return Notify.error(alertContent);
        }
        setIsLoading(false);
        return Notify.error("Failed to add members.");
      });
  }

  function getConferenceText(conference = {}) {
    const {
      timeStarted = {},
      timeEnded = {},
      createdAt,
      label = "",
      dob = "",
      comments = "",
      chiefComplaint = "",
      participants = [],
    } = conference;

    const createdDate = util.formatConferenceDate(createdAt);

    const duration = util.formatConferenceDuration(timeStarted.iso, timeEnded.iso);

    const participantNames = participants
      .map((participant) => {
        return participant.displayName;
      })
      .join(", ");

    return [
      "[Conference log]",
      "Patient/Topic: " + label,
      "Date of Birth:" + dob,
      "Date and Time: " + createdDate.date + " - " + createdDate.time,
      "Duration: " + duration,
      "Chief Complaint: " + chiefComplaint,
      "Notes: " + comments,
      "---",
      "Participants: " + participantNames,
    ].join("\n");
  }

  function handleOpenForwardModal(conference) {
    const currentUser = Parse.User.current();

    const user = {
      ...currentUser,
      objectId: currentUser.id,
    };

    const text = getConferenceText(conference);

    const message = {
      text,
      user,
    };

    // Set message to forward
    setMessageToForward(message);

    // Set selected conference
    setSelectedConference(conference);

    setForwardModalShown(true);
  }

  function handleForwardModalClose() {
    setForwardModalShown(false);
  }

  function handleConfirmOnClose(id) {
    if (confirmData.identifier === "delete") {
      handleDeleteSchedule(id);
    }
    if (confirmData.identifier === "complete") {
      // handleCompleteSchedule(id);
      console.log("complete");
    }
  }

  function handleTabSelect(tab) {
    switch (tab) {
      case "LOGS":
        return getConferenceCalls()(dispatch);
      case "SCHEDULES":
        return getSchedules()(dispatch);
      case "REQUESTS":
        return "";
    }
  }

  function handleClientLoad() {
    loadGoogleApi(
      {
        apiKey: config.REACT_APP_GOOGLE_API_KEY,
        clientId: config.REACT_APP_GOOGLE_CLIENT_ID,
      },
      () => {}
    )(dispatch);
  }

  function handleAuthClick() {
    signInToGoogle()(dispatch);
  }

  function render() {
    return (
      <>
        <ConferenceNav onTabSelect={(tab) => handleTabSelect(tab)} />

        {/* Show alert for accepting rejecting active call */}
        {roomId ? renderCallControls() : ""}

        {/* The title */}
        {/* <div className="hc-primary-text" style={{marginLeft: '20px', fontWeight: '500'}}>
                    CONFERENCE LOGS
                </div> */}

        {activeConferenceTab === "LOGS" ? (
          /* List of conference call history */
          <div className="Tab-Container mb-0">
            {!isLoadingConferences ? (
              <div className="list-group modified-collection" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                {conferences.map((c) => (
                  <ConferenceItem
                    key={c.objectId}
                    conference={c}
                    onOpenUpdateModal={() => handleOpenUpdateModal(c)}
                    onOpenForwardModal={() => handleOpenForwardModal(c)}
                  />
                ))}
              </div>
            ) : (
              ""
            )}
            <TabLoader isLoading={isLoadingConferences} />
          </div>
        ) : (
          ""
        )}

        {activeConferenceTab === "SCHEDULES" ? (
          /* List of scheduled conference call */
          <div className="Tab-Container" style={{ height: "calc(100% - 141px)" }}>
            <Fragment>
              {!isLoadingSchedules ? (
                <Fragment>
                  <div className="list-group" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    {schedules.map((c) => (
                      <ScheduleItem
                        onJoin={(objectId) => joinConference(objectId)}
                        key={c.objectId}
                        conference={{ ...c, currentUser: Parse.User.current() }}
                        onOpenUpdateModal={() => handleOpenUpdateSchedule(c)}
                        onOpenConfirmModal={(cData) => handleOpenConfirmModal(c, cData)}
                        messageGroup={() => handleMessageGroup(c)}
                      />
                    ))}
                  </div>
                  {schedules.length === 0 && <div className="text-empty tab-placeholder">No upcoming schedules</div>}
                </Fragment>
              ) : (
                ""
              )}
              <TabLoader isLoading={isLoadingSchedules} />
            </Fragment>
          </div>
        ) : (
          ""
        )}

        {activeConferenceTab === "REQUESTS" ? (
          /* List of scheduled conference call */
          <div className="Tab-Container" style={{ height: "calc(100% - 141px)" }}>
            <Fragment>
              {!isLoadingSchedules ? (
                <Fragment>
                  <div className="list-group" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    {schedules.map((c) => (
                      <RequestItem
                        onJoin={(objectId) => joinConference(objectId)}
                        key={c.objectId}
                        conference={{ ...c, currentUser: Parse.User.current() }}
                        onOpenUpdateModal={() => handleOpenUpdateSchedule(c)}
                        onOpenConfirmModal={(cData) => handleOpenConfirmModal(c, cData)}
                      />
                    ))}
                  </div>
                  {schedules.length === 0 && <div className="text-empty tab-placeholder">No telemed requests</div>}
                </Fragment>
              ) : (
                ""
              )}
              <TabLoader isLoading={isLoadingSchedules} />
            </Fragment>
          </div>
        ) : (
          ""
        )}

        {forwardModalShown ? (
          <ForwardModal
            isOpen={forwardModalShown}
            modalClose={() => handleForwardModalClose()}
            message={messageToForward}
          />
        ) : (
          ""
        )}
        {updateScheduleShown && (
          <ManageEventModal
            method="update"
            dataEvent={selectedConference}
            isOpen={updateScheduleShown}
            onClose={() => setUpdateScheduleShown(false)}
          />
        )}
        <ConfirmModal
          event={selectedConference}
          isOpen={confirmShown}
          onClose={(id) => handleConfirmOnClose(id)}
          title={confirmData.title}
          message={confirmData.body}
        />
        {/* <UpdateScheduleModal conference={selectedConference} isOpen={updateScheduleShown} onClose={() => setUpdateScheduleShown(false)} /> */}
        <UpdateConferenceModal
          conference={selectedConference}
          isOpen={updateModalShown}
          isActiveCall={isActiveCall}
          onClose={(isUpdated) => {
            handleSetUpdateModal(isUpdated);
            setUpdateModalShown(false);
            setIsActiveCall(false);
          }}
        />
        <Loader isLoading={isLoading} message={loadingMessage} />
      </>
    );
  }

  return render();
}

export default ConferenceContainer;
