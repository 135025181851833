import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import TabSearch from "./TabSearch";
import MessageNav from "./MessageNav";
import "./TabList.css";

import {
  setActiveTab,
  fetchThreads,
  fetchThreadsUnread,
  fetchThreadsForMe,
  hideTagsModal,
  filterThreads,
  fetchRecentThreads,
  searchMessage,
} from "../../actions/ThreadAction";
import { fetchPriorities } from "../../actions/PriorityAction";
import { newGroup } from "../../actions/GroupAction";
import MessageContainer from "./MessageTab/MessageContainer";
import GroupContainer from "./GroupTab/GroupContainer";
import ContactContainer from "./ContactTab/ContactContainer";
import PatientContainer from "./PatientTab/PatientContainer";
import ConferenceContainer from "./ConferenceTab/ConferenceContainer";
import ProgressNotesCard from "./ProgressNoteTab/ProgressNotesCard";
import TodosContainer from "./TodosTab";
import ProfileSetting from "./ProfileSetting/ProfileSetting";
import CreateGroup from "../TabList/GroupTab/CreateGroup";
import CreatePatient from "../TabList/PatientTab/CreatePatient";
import InviteContact from "./ContactTab/InviteContact";
import TagsModal from "../TabList/MessageTab/TagsModal";
import TabMenu from "./TabMenu";
import TodosTab from "./TodosTab";
import CreateChat from "../Pages/CreateChat";

export class index extends Component {
  constructor() {
    super();
    this.state = {
      typeValue: "",
      openNav: false,
      openCreateGroupModal: false,
      openCreatePatientModal: false,
      openInviteContactModal: false,
      isSearching: false,
      selectedLevel: "high",
      selectedTab: "ALL",
    };

    this.closeNav = this.closeNav.bind(this);
    this.onCloseTagsModal = this.onCloseTagsModal.bind(this);
    this.handleFilteredTasks = this.handleFilteredTasks.bind(this);
    this.debouncedSearch = debounce(this.handleSearch, 500);
  }

  handleTabClick(tab) {
    this.props.setActiveTab(tab);
  }
  
  handleSearch = (query) => {
    this.props.searchMessage(query)
    .then((res) => {
      this.setState({ isSearching: false });
    });
  };

  typing(value) {
    this.setState(
      {
        typeValue: value,
        isSearching: true,
      },
      () => {
        if (this.props.activeTab === "message") {
          this.debouncedSearch(value);
        }
        // const debouncedFetchDiagnosisList = debounce(this.props.fetchThreads, 500);
        // debouncedFetchDiagnosisList("", true, 0, "", value);
      }
    );
  }

  openProfileSetting() {
    this.setState({
      openNav: true,
    });
  }

  closeNav() {
    this.setState({
      openNav: false,
    });
  }

  openCreateModal() {
    if (this.props.activeTab === "group") {
      this.setState({
        openCreateGroupModal: true,
        openCreatePatientModal: false,
      });
    } else if (this.props.activeTab === "chart") {
      this.setState({
        openCreateGroupModal: false,
        openCreatePatientModal: true,
      });
    }
  }

  closeCreateModal() {
    this.setState({
      openCreateGroupModal: false,
      openCreatePatientModal: false,
      openInviteContactModal: false,
    });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        typeValue: "",
      });
    }
    return null;
  }

  //////
  // componentDidMount() {
  //   // Quick open from activity list
  //   if (this.props.activeMessageTab === "TASKS") {
  //     this.handleTabSelect("TASKS");
  //   }
  //   console.log("activeMessageTab", this.props.activeMessageTab);
  // }

  componentDidUpdate() {}

  showInviteModal() {
    this.setState({ openInviteContactModal: true });
  }

  handleTabSelect(tab) {
    const {
      fetchThreads,
      fetchThreadsUnread,
      fetchThreadsForMe,
      fetchPriorities,
      initialLoad,
      pubNub,
      fetchRecentThreads,
      activeUnreadTab,
      read,
    } = this.props;

    this.setState({ selectedTab: tab });

    switch (tab) {
      case "ALL":
        !initialLoad && fetchThreads("", false, 0, "", "", read);
        break;
      case "UNREAD":
        // comment duplicate api call from MessageNav ln. 34
        // fetchThreadsUnread();
        // if (activeUnreadTab !== "urgent") {
        //   fetchThreadsForMe();
        // }
        break;
      case "TASKS":
        fetchPriorities();
        break;
      case "ALERTS":
        fetchPriorities();
        break;
      case "RECENT":
        fetchRecentThreads();
        break;
      default:
    }
  }

  onCloseTagsModal() {
    this.props.hideTagsModal();
  }

  handleFilteredTasks(selectedLevel) {
    this.setState({ selectedLevel });
  }

  render() {
    const { selectedLevel, selectedTab } = this.state;
    let Tab;
    switch (this.props.activeTab) {
      case "message":
        Tab = (
          <MessageContainer
            typeValue={""}
            history={this.props.history}
            path={this.props.path}
            location={this.props.location}
            match={this.props.match}
            pubNub={this.props.pubNub}
          />
        );
        break;

      case "create_chat":
        Tab = (
          <ContactContainer
            typeValue={this.state.typeValue}
            history={this.props.history}
            path={this.props.path}
            location={this.props.location}
            match={this.props.match}
            itemType="check_contact"
          />
        );
        break;

      case "group":
        Tab = (
          <GroupContainer
            typeValue={this.state.typeValue}
            history={this.props.history}
            path={this.props.path}
            location={this.props.location}
            match={this.props.match}
          />
        );
        break;

      case "chart":
        Tab = (
          <PatientContainer
            typeValue={this.state.typeValue}
            history={this.props.history}
            path={this.props.path}
            location={this.props.location}
            match={this.props.match}
          />
        );
        break;

      case "contact":
        Tab = (
          <ContactContainer
            typeValue={this.state.typeValue}
            history={this.props.history}
            path={this.props.path}
            location={this.props.location}
            match={this.props.match}
            itemType="contact"
          />
        );
        break;

      case "conference":
        Tab = (
          <ConferenceContainer
            history={this.props.history}
            path={this.props.path}
            location={this.props.location}
            match={this.props.match}
          />
        );
        break;

      case "todos":
        Tab = (
          <TodosTab
            typeValue={this.state.typeValue}
            history={this.props.history}
            path={this.props.path}
            location={this.props.location}
            match={this.props.match}
          />
        );
        break;

      case "progressNote":
        Tab = (
          <ProgressNotesCard
            typeValue={this.state.typeValue}
            history={this.props.history}
            path={this.props.path}
            location={this.props.location}
            match={this.props.match}
          />
        );
        break;

      default:
        break;
    }

    let tabStyle = {};
    if (this.props.hideList) {
      tabStyle.display = "none";
    } else {
      tabStyle.display = "flex";
    }

    if (this.props.isMobile) {
      tabStyle.maxWidth = "100%";
      tabStyle.minWidth = "100%";
      tabStyle.border = "0";
    }

    return (
      <div className="TabList-Panel" style={tabStyle}>
        {this.props.isSending && (
          <div
            style={{
              height: "100%",
              width: "100%",
              background: "transparent",
              position: "absolute",
              zIndex: "1",
            }}
          ></div>
        )}
        {(this.props.activeTab === "group" ||
          this.props.activeTab === "chart") && (
          <div className="d-flex" style={{ margin: "10px 20px 10px 20px" }}>
            <TabSearch
              activeTab={this.props.activeTab}
              typeValue={this.state.typeValue}
              typing={this.typing.bind(this)}
              isSearching={this.state.isSearching}
            />
            <TabMenu openCreateModal={this.openCreateModal.bind(this)} />
          </div>
         )}
        {this.props.activeTab === "contact" ? (
          <div className="Tab-Row">
            <button type="submit" className="btn btn-hubchart-rounded" onClick={this.showInviteModal.bind(this)}>
              Invite contact &nbsp;
              <i className="fas fa-user-plus fa-sm"></i>
            </button>
          </div>
        ) : (
          ""
        )}
        {this.props.activeTab === "message" ? (
          <div className="d-flex" style={{ margin: "10px 20px 10px 20px" }}>
            <MessageNav onTabSelect={(tab) => this.handleTabSelect(tab)} />
            <TabMenu openCreateModal={this.openCreateModal.bind(this)} />
          </div>
        ): (
          ""
        )}
        {Tab}
        <ProfileSetting closeNav={this.closeNav} />
        {this.props.tagsModalShown && (
          <TagsModal
            recent={this.props.recentToTag}
            isOpen={this.props.tagsModalShown}
            onClose={this.onCloseTagsModal}
          />
        )}
        {this.state.openCreateGroupModal && (
          <CreateGroup
            newGroup={this.props.newGroup}
            history={this.props.history}
            isOpen={this.state.openCreateGroupModal}
            modalClose={this.closeCreateModal.bind(this)}
          />
        )}
        {this.state.openCreatePatientModal && (
          <CreatePatient
            newGroup={this.props.newGroup}
            isOpen={this.state.openCreatePatientModal}
            modalClose={this.closeCreateModal.bind(this)}
          />
        )}
        {this.state.openInviteContactModal && (
          <InviteContact isOpen={this.state.openInviteContactModal} modalClose={this.closeCreateModal.bind(this)} />
        )}
        {this.props.activeTab === "create_chat" && this.props.isMobile && this.props.selectedContacts.length > 0 && (
          <Fragment>
            <CreateChat />
            <br />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeTab: state.thread.activeTab,
  initialLoad: state.thread.initialLoad,
  tagsModalShown: state.thread.tagsModalShown,
  recentToTag: state.thread.recentToTag,
  isSending: state.thread.isSending,
  selectedContacts: state.contact.selectedContacts,
  activeUnreadTab: state.thread.activeUnreadTab,
  read: state.thread.read,
  activeMessageTab: state.thread.activeMessageTab,
});

export default connect(mapStateToProps, {
  fetchThreadsUnread,
  fetchThreadsForMe,
  fetchPriorities,
  hideTagsModal,
  setActiveTab,
  fetchThreads,
  filterThreads,
  newGroup,
  fetchRecentThreads,
  searchMessage,
})(index);
