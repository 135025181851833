import { parseAction } from './REST';
import React from 'react';
import Parse from 'parse';
import { toast } from 'react-toastify';
import Toast from './Toast'
import { SAVE_DOCUMENT, GET_DOCUMENT, DOCUMENT_FETCH_STATUS, NEW_DOCUMENT } from './types';
import config from '../config'

export const UUID = () => {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c ==='x' ? r : (r&0x3|0x8)).toString(16).toUpperCase();
    });
    return uuid;
}

const getFileExtention = (name) =>  {
    return name.substr((~-name.lastIndexOf(".") >>> 0) + 2);
}

export const saveDocument = (data, target) => dispatch => {
    let urlPreview = {
        url : ""
    };
    const pdfDefault = require('../assets/images/pdf.png')
    const ext = getFileExtention(data.picture.name);
    if (ext === "pdf") {
        urlPreview.url = pdfDefault;
    } else {
        urlPreview.url = data.picture.url;
    }
    let _target = "";
    const name = data.user.displayName
    switch(target) {
        case "encounter_notes":
            _target = "Encounter Notes";
            break;

        case "meds_rx":
            _target = "Meds/Rx";
            break;

        case "authorization":
            _target = "Authorization";
            break;

        case "labs":
            _target = "Labs";
            break;

        case "radiology":
            _target = "Radiology";
        break;

        case "pathology":
            _target = "Pathology";
        break;

        case "consult_notes":
            _target = "Consult Notes";
        break;

        case "hospital_records":
            _target = "Hospotal Records";
        break;

        case "photos":
            _target = "Photo Files";
        break;

        case "admin_docs":
            _target = "Admin/Legal Docs";
        break;

        case "other_files":
            _target = "Other Files";
        break;
    }


    toast.info(<Toast picture={urlPreview} name={name} message={"Uploading file to " + _target}/>, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    });

    let Document = Parse.Object.extend("Document");
    let newDocument = new Document();

    let User = Parse.Object.extend("User");
    let user = new User;
    user.id = Parse.User.current().id;

    let originalSender = new User;
    originalSender.id = data.user.objectId;

    let Circle = Parse.Object.extend("Circle");
    let group = new Circle;
    group.id = data.circle.objectId;

    newDocument.set("circle", group);
    newDocument.set("user", user);
    newDocument.set("originalSender", originalSender);
    newDocument.set("photo", data.picture);
    newDocument.set("emrType", target);
    newDocument.set("originalDateSent", new Date(data.createdAt));

    const uuid = UUID();
    newDocument.set("uuid", uuid);

    newDocument.save()
    .then((savedDocu) => {
        toast.success(<Toast picture={urlPreview} name={name} message={"File has been uploaded"}/>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });

        let jsonObject = JSON.stringify(savedDocu.toJSON());
            jsonObject = JSON.parse(jsonObject);

        dispatch({
            type: NEW_DOCUMENT,
            id : data.circle.objectId,
            document : jsonObject,
            docuType : target
        })
    }, (error) => {
        console.log(error);
        toast.error(<Toast picture={urlPreview} name={name} message={"Failed to upload file to " + _target}/>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    });
}

export const fetchDocuments = (circleObjectId, type, ignoreStore=false) => dispatch => {
    
    if (!ignoreStore) {
        dispatch({
            type: DOCUMENT_FETCH_STATUS,
            status : "fetching",
            docuType : type
        })   
    }

    return parseAction("get", config.BASE_URL + '/parse/classes/Document',
		{
			where: {
                "circle":{
                  "__type": "Pointer",
                  "className":"Circle",
                  "objectId": circleObjectId
                },
                "emrType" : type
            },
            include: ["user", "originalSender"],
            order: "-createdAt"
		}
	)
	.then(docu => {
        if (docu.results.length !== 0 && !ignoreStore) {
            dispatch({
                type: SAVE_DOCUMENT,
                id : circleObjectId,
                documents : docu.results,
                status : "success",
                docuType : type
            })
        }
        
        return docu.results;
	});
}


export const getDocument = (circleObjectId) => dispatch => {
    dispatch({
        type: GET_DOCUMENT,
        id : circleObjectId
    })
}

export const fetchFilteredDocuments = (circleId, emrType, page, pageSize) => dispatch => {
    const method = "post";
    const url = config.BASE_URL + "/parse/functions/getDocument";
    const data = {
        circleId: circleId,
        emrType: emrType || "",
        page: page,
        pageSize: pageSize,
    };

    return parseAction(method, url, data, { "Content-Type": "application/json", })
    .then((response) => {
        return response.result;
    })
    .catch((error) => {
        console.error(error);
    });
}

  export const categorizeDocument = (circleId, emrType, attachmentId) => dispatch => {
    const method = "post";
    const url = config.BASE_URL + "/parse/functions/categorizeDocument";
    const data = {
      attachmentId: attachmentId,
      circleId: circleId,
      emrType: emrType,
    };

    return parseAction(method, url, data, { "Content-Type": "application/json", })
      .then((response) => {
        return response.result;
      })
      .catch((error) => {
        console.error(error);
      });
  }
