import React, { Component, Fragment } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  sendNewMessage,
  setGhostThread,
  setActiveThread,
  fetchPrivateMessages,
  fetchGroupMessages,
  fetchAdhocMessages,
  fetchSubgroupMessages,
  archivedMessage,
  deleteMessage,
} from "../../actions/ThreadAction";
import { seenMessage } from "../../actions/SeenAction";
import { createForwardedMessage } from "../../actions/GroupAction";
import { checkIfBlocked, unblockContact, getContactStatus } from "../../actions/ContactAction";
import ConversationHeader from "./ConversationHeader";
import ConversationBody from "./ConversationBody";
import PendingNotificationModal from "./PendingNotificationModal";
// import PatientInfoModal from './Setting/PatientInfoModal';
import moment from "moment";
import Parse from "parse";
import sort from "fast-sort";
import "./Conversation.css";
import Logo from "../../assets/images/logo-blue.png";
import SideTab from "./SideTab";

export class Conversation extends Component {
  constructor() {
    super();
    this.state = {
      groupMembers: {
        orgAdmins: [],
        orgMembers: [],
        regMembers: [],
        guests: [],
      },
      groupThreadId: "",
      contactObjectId: "",
      openDocument: false,
      blocked: {
        userHasBlocked: false,
        userIsBlocked: false,
      },
      checkingIfBlocked: false,
      pendingModalShown: false,
      isFetching: false,
      isSearchOpen: false,
    };

    this.isDragging = this.isDragging.bind(this);
  }

  componentDidMount() {
    let path = this.props.location.pathname;
    let routeObjectId;
    if (path !== "/u") {
      routeObjectId = this.props.location.pathname.replace("/u/", "");
    }

    if (routeObjectId) {
      routeObjectId = routeObjectId.replace("s_", "");
      if (routeObjectId !== this.props.activeThread.threadId) {
        this.props.setGhostThread(routeObjectId, this.props.threads);
      }
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    let path = this.props.location.pathname;
    let routeObjectId;
    if (path !== "/u") {
      routeObjectId = this.props.location.pathname.replace("/u/", "");
    } else {
      // SET ACTIVE THREAD TO EMPTY
      if (this.props.activeThread.threadId) {
        this.props.setActiveThread("");
      }
    }

    // TODO Fix when there is no thread for the route
    // if (this.props.activeThread.threadType) {
    //     console.log("undenifiend");
    // } else {
    //     console.log("meron")
    // }

    if (routeObjectId) {
      routeObjectId = routeObjectId.replace("s_", "");
      let tempThreadId = routeObjectId;
      if (this.props.activeThread.threadType === "private") {
        let idArray = [Parse.User.current().id, routeObjectId];
        idArray = sort(idArray).asc((p) => p.toLowerCase());
        tempThreadId = idArray.join("_");
      }

      if (tempThreadId !== this.props.activeThread.threadId) {
        // USER CLICK BROWSER BACK BUTTON
        this.props.setGhostThread(routeObjectId, this.props.threads);
      }
    }
    if (this.props.activeThread.threadType === "group") {
      //Save groupObjectId if not equal to state
      if (this.props.activeThread.threadId !== this.state.groupThreadId) {
        this.setState({
          groupThreadId: this.props.activeThread.threadId,
          // pendingModalShown: this.props.activeThread.groupType === 'patient' ? true : false,
        });
      }
    } else if (this.props.activeThread.threadType === "private") {
      if (this.props.activeThread.partnerObjectId !== this.state.contactObjectId) {
        this.setState({
          contactObjectId: this.props.activeThread.partnerObjectId,
          checkingIfBlocked: true,
        });

        this.setState({ isFetching: true });
        // debugger;
        this.props.getContactStatus(this.props.activeThread.partnerObjectId, this.props.activeThread).then(() => {
          this.setState({
            isFetching: false,
          });
        });
      }
    }
    //NOTE Check if has conversation else Fetch conversation here

    if (prevProps.activeThread.threadId !== this.props.activeThread.threadId) {
      // this.props.closeDocument();
      this.setState({ isSearchOpen: false });
      let now = moment().toDate();
      if (this.props.conversation.initialLoad) {
        //fetchResult === "none" means that our object is initialState
        if (
          this.props.conversation.initialLoad.isFetching === false &&
          this.props.conversation.initialLoad.fetchResult === "none"
        ) {
          switch (this.props.activeThread.threadType) {
            case "private":
              this.props.fetchPrivateMessages(
                this.props.activeThread.partnerObjectId,
                now,
                this.props.activeThread.threadId,
                false
              );
              break;

            case "group":
              if (this.props.activeThread.subgroupSession) {
                this.props.fetchSubgroupMessages(this.props.activeThread.threadId, now);
              } else {
                // NOTE we pass threadId as groupObjectId since threadId is base on the groupObjectId
                this.props.fetchGroupMessages(this.props.activeThread.threadId, now, this.props.activeThread.threadId);
              }
              break;

            case "adhoc":
              // NOTE we pass threadId as groupObjectId since threadId is base on the groupObjectId
              this.props.fetchAdhocMessages(this.props.activeThread.threadId, now);
              break;

            default:
              break;
          }
        } else {
          // NOTE Seen message here since conversation already has a messages object
          switch (this.props.activeThread.threadType) {
            case "private":
              let privateMessages = this.props.conversation.messages;
              if (privateMessages.length !== 0) {
                const messageToBeSeen = privateMessages[privateMessages.length - 1];
                this.props.seenMessage(
                  this.props.activeThread.threadId,
                  messageToBeSeen.objectId,
                  (messageToBeSeen.originalMessageDate || {}).iso,
                  "NotSessionMessage"
                );
              }
              break;

            case "group":
              if (this.props.activeThread.subgroupSession) {
                // TODO for subgroup seen message
                return null;
              }
              let groupMessages = this.props.conversation.messages;
              if (groupMessages.length !== 0) {
                const messageToBeSeen = groupMessages[groupMessages.length - 1];
                this.props.seenMessage(
                  this.props.activeThread.threadId,
                  messageToBeSeen.objectId,
                  (messageToBeSeen.originalMessageDate || {}).iso,
                  "NotSessionMessage"
                );
              }

              break;

            case "adhoc":
              // TODO For adhoc seen message
              let adhocMessage = this.props.conversation.messages;
              if (adhocMessage.length !== 0) {
                const messageToBeSeen = adhocMessage[adhocMessage.length - 1];
                this.props.seenMessage(
                  this.props.activeThread.threadId,
                  messageToBeSeen.objectId,
                  (messageToBeSeen.originalMessageDate || {}).iso,
                  "NotSessionMessage"
                );
              }
              break;

            default:
              break;
          }
        }
      }
    }

    return null;
  }

  componentDidUpdate() {}

  // checkIfBlocked(userObjectId) {
  //    return new Promise((resolve, reject) => {
  //         const params =  { userObjectId: userObjectId };
  //         Parse.Cloud.run("checkIfBlocked", params).then(req => {
  //             console.log(req);
  //             resolve(req.isBlocked);
  //         }).catch(error => {
  //             reject(error);
  //         })
  //     })
  // }

  saveGroupMembers(threadId, members) {
    if (this.state.groupThreadId === threadId) {
      this.setState({
        groupMembers: members,
      });
    }
  }

  saveRegularMembers(threadId, members) {
    if (this.state.groupThreadId === threadId) {
      this.setState({
        groupMembers: {
          ...this.state.groupMembers,
          regMembers: members,
        },
      });
    }
  }

  saveGuestMembers(threadId, members) {
    if (this.state.groupThreadId === threadId) {
      this.setState({
        groupMembers: {
          ...this.state.groupMembers,
          regMembers: members,
        },
      });
    }
  }

  saveContactRoster() {
    // if (this.state.)
  }

  isDragging() {
    this.props.openDocument();
  }

  handleNewMember(users = []) {
    console.log("Added new member");
    console.log(users);

    const regMembers = [...this.state.groupMembers.regMembers, ...users];

    this.setState({
      groupMembers: {
        ...this.state.groupMembers,
        regMembers,
      },
    });
  }

  handlePendingModalShown(bool) {
    this.setState({
      pendingModalShown: bool,
    });
  }

  handleOpenSearchBar(bool) {
    this.setState({
      isSearchOpen: bool,
    });
  }

  handleCloseSearchBar() {
    this.setState({
      isSearchOpen: false,
    });
  }

  showPatientInfoModal() {
    // this.setState({
    //     patientInfoShown: true
    // });
    const filesUrl = `${window.location.origin}/facesheet/${this.props.activeThread.threadId}`;
    window.open(filesUrl, "Hubchart Facesheet");
  }
  hidePatientInfo() {
    this.setState({
      patientInfoShown: false,
    });
  }

  render() {
    const { groupThreadId, pendingModalShown, patientInfoShown } = this.state;
    const { activeThread, location } = this.props;
    let path = location.pathname;

    let conversationStyle = {};
    if (this.props.hideConversation) {
      conversationStyle.display = "none";
    } else {
      conversationStyle.display = "flex";
    }

    if (this.props.isMobile) {
      conversationStyle.minWidth = "0";
    }

    return (
      <div className="Main-Panel" style={conversationStyle}>
        <ConversationHeader
          onNewMember={this.handleNewMember.bind(this)}
          installationId={this.props.installationId}
          openCCM={this.props.openCCM}
          closeCCM={this.props.closeCCM}
          ccmIsOpen={this.props.ccmIsOpen}
          openDocument={this.props.openDocument}
          closeDocument={this.props.closeDocument}
          documentIsOpen={this.props.documentIsOpen}
          groupMembers={this.state.groupMembers}
          history={this.props.history}
          isMobile={this.props.isMobile}
          setActiveThread={this.props.setActiveThread}
          activeThread={this.props.activeThread}
          saveGroupMembers={this.saveGroupMembers.bind(this)}
          saveRegularMembers={this.saveRegularMembers.bind(this)}
          isDragging={this.isDragging}
          unblockContact={this.props.unblockContact}
          blocked={this.props.blocked}
          contactStatus={this.props.contactStatus}
          isFetching={this.state.isFetching}
          handleOpenSearchBar={this.handleOpenSearchBar.bind(this)}
          isSearchOpen={this.state.isSearchOpen}
        />
        <Route
          path="/u/:objectId"
          strict
          exact
          render={({ match }) => (
            <ConversationBody
              objectId={match.params.objectId}
              {...this.props}
              groupMembers={this.state.groupMembers}
              isDragging={this.isDragging}
              blocked={this.state.blocked}
              checkingIfBlocked={this.state.checkingIfBlocked}
              contactStatus={this.props.contactStatus}
              history={this.props.history}
              isSearchOpen={this.state.isSearchOpen}
              handleCloseSearchBar={this.handleCloseSearchBar.bind(this)}
            />
          )}
        />
        {pendingModalShown && (
          <PendingNotificationModal
            isOpen={pendingModalShown}
            onClose={(bool) => {
              this.handlePendingModalShown(false);
              if (bool) {
                this.showPatientInfoModal();
              }
            }}
          />
        )}
        {path === "/u" && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={Logo}
              style={{
                width: "auto",
                height: 180,
                opacity: "0.3",
              }}
            />

            <div style={{ marginBottom: 40, textAlign: "center", opacity: "0.6" }}>
              <span>Conversations loaded</span>
              <br />
              <span>Please select a thread to view your conversation</span>
            </div>
          </div>
        )}
        {/* {patientInfoShown && (
                    <PatientInfoModal 
                        threadId={groupThreadId} 
                        isOpen={patientInfoShown} 
                        onClose={this.hidePatientInfo.bind(this)} />
                )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contactStatus: state.thread.conversation.contactStatus,
  activeThread: state.thread.activeThread,
  conversation: state.thread.conversation,
  blocked: state.thread.conversation.blocked,
  threads: state.thread.threads,
  appState: state.session.appState,
  installationId: state.session.installationId,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchPrivateMessages,
    fetchGroupMessages,
    fetchAdhocMessages,
    fetchSubgroupMessages,
    setGhostThread,
    setActiveThread,
    sendNewMessage,
    archivedMessage,
    seenMessage,
    createForwardedMessage,
    deleteMessage,
    checkIfBlocked,
    unblockContact,
    getContactStatus,
  })(Conversation)
);
