import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { markThreadAsRead, deleteRecent, pinRecent, unSeenMessage } from "../../../actions/SeenAction";
import Notify from "../../Common/Notify";
import { showTagsModal } from "../../../actions/ThreadAction";

function MessageMenu(props) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  function setLoading(boolean, message) {
    setIsLoading(boolean);
    setLoadingMessage(message);
  }

  function handleClick(e) {
    e.stopPropagation();
  }

  function handleMarkAsRead(e) {
    e.stopPropagation();

    setLoading(true, "Please wait...");

    markThreadAsRead(props.threadId)(dispatch)
      .then(() => {
        Notify.success("Thread marked as read!");

        setLoading(false);
      })
      .catch(() => {
        Notify.error("Failed to mark as read");

        setLoading(false);
      });
  }

  function handleMarkAsUnread(e) {
    e.stopPropagation();

    setLoading(true, "Please wait...");

    unSeenMessage(props.threadId)(dispatch)
      .then(() => {
        Notify.success("Thread marked as unread!");

        setLoading(false);
      })
      .catch(() => {
        Notify.error("Failed to mark as unread");

        setLoading(false);
      });
  }

  function handleRemove(e) {
    e.stopPropagation();

    deleteRecent(props.recentId)(dispatch);
  }

  function handlePin(e, isPinned) {
    e.stopPropagation();

    pinRecent(props.recentId, isPinned)(dispatch);
  }

  function handleSetTags(e) {
    e.stopPropagation();

    showTagsModal(props.recent)(dispatch);
  }

  function hasUnread() {
    const {
      unreadMessagesCount = 0,
      hasUnreadMessages = false,
      hasUnreadAttention = false,
      hasUnreadUrgent = false,
      hasAttention = false,
      hasUrgent = false,
    } = props.recent;

    return unreadMessagesCount ||
      hasUnreadMessages ||
      hasUnreadAttention ||
      hasUnreadUrgent ||
      hasAttention ||
      hasUrgent
      ? true
      : false;
  }

  const clearButtonTitle = props.isPinned
    ? "Unpin first to clear from inbox."
    : "Hide thread from inbox until a new message is received.";

  return (
    <div className="message-menu-overlay">
      <div className="dropdown mr-2">
        <button
          onClick={(e) => handleClick(e)}
          type="button"
          className="btn contact-menu-button dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fas fa-ellipsis-v"></i>
        </button>
        <div
          style={{ boxShadow: "0px 4px 8px 0px #888888" }}
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <h6 className="dropdown-header">Action</h6>
          {props.isPinned ? (
            <a onClick={(e) => handlePin(e, false)} className="dropdown-item" href="#!">
              <i className="fas fa-thumbtack" style={{ marginRight: "5px" }}></i>
              Unpin
            </a>
          ) : (
            <a onClick={(e) => handlePin(e, true)} className="dropdown-item" href="#!">
              <i className="fas fa-thumbtack" style={{ marginRight: "5px" }}></i>
              Pin
            </a>
          )}
          {props.recent.threadType === "group" || props.recent.threadType === "private" ? (
            <a onClick={(e) => handleSetTags(e)} className="dropdown-item" href="#!">
              <i className="fas fa-hashtag" style={{ marginRight: "5px" }}></i>
              Set/Unset tags
            </a>
          ) : (
            ""
          )}
          {(props.activeThread.threadId !== props.threadId) && (
            <>
            {hasUnread() && (
              <a onClick={(e) => handleMarkAsRead(e)} className="dropdown-item" href="#!">
                <i className="fas fa-check" style={{ marginRight: "5px" }}></i>
                Mark as read
              </a>
            )}
            {!hasUnread() && (
              <a onClick={(e) => handleMarkAsUnread(e)} className="dropdown-item" href="#!">
                <i className="fas fa-check" style={{ marginRight: "5px" }}></i>
                Mark as unread
              </a>
            )}
            </>
          )}
          <a
            onClick={(e) => handleRemove(e)}
            className={props.isPinned ? "dropdown-item disabled" : "dropdown-item"}
            href="#!"
            data-toggle="tooltip"
            title={clearButtonTitle}
            data-placement="bottom"
          >
            <i className="fas fa-ban" style={{ marginRight: "5px" }}></i>
            Clear
          </a>
        </div>
      </div>
    </div>
  );
}

export default MessageMenu;
