import React, { Component } from "react";
import { parseAction } from "../../../actions/REST";
import "../../Pages/Login/Login.css";
import Loader from "../../Common/Loader";
import Member from "./Member";
import Parse from "parse";
import { assignAdmin, removeAdmin, removeMember, inviteMember } from "../../../actions/GroupAction";
import { addGuestsToCircle } from "../../../actions/CircleAction";
import { connect } from "react-redux";
import Notify from "../../Common/Notify";
import _ from "lodash";
import MemberList from "./MemberList";
import AddMember from "./AddMember";
import AddGuest from "./AddGuest";
import config from "../../../config";

const $ = window.$;

export class MemberListModal extends Component {
  constructor() {
    super();
    this.state = {
      owner: {},
      admins: [],
      members: [],
      guests: [],

      isLoading: false,
      loadingMessage: "",
      isCreator: false,
      isAdmin: false,
      group: {},
      currentTab: "list",
    };

    this.assignAdmin = this.assignAdmin.bind(this);
    this.removeAdmin = this.removeAdmin.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.inviteMember = this.inviteMember.bind(this);
    this.removeGuest = this.removeGuest.bind(this);
  }

  componentDidMount() {
    console.log("MemberListModal");
    if (this.props.isOpen) {
      $("#memberListModal").modal({
        backdrop: "static",
        keyboard: false,
      });

      $("#memberListModal").modal("show");

      this.fetchData();
    } else {
      $("#memberListModal").modal("hide");
    }
    $("#memberListModal").on(
      "hidden.bs.modal",
      function(e) {
        this.props.onClose();
      }.bind(this)
    );
  }

  setLoading(isLoading, loadingMessage) {
    this.setState({ isLoading, loadingMessage });
  }

  fetchData() {
    const groupObjectId = this.props.threadId;

    const promise = Promise.all([this.fetchMembers(groupObjectId)]);

    promise.then((result) => {
      this.setMembers(result[0]);
      this.setGroup(result[0]);
    });
  }

  fetchMembers(groupObjectId) {
    const method = "get";
    const url = config.BASE_URL + "/api/circle-members/" + groupObjectId;
    const data = {};

    this.setLoading(true);

    return parseAction(method, url, data)
      .then((response) => {
        this.setLoading(false);

        return response.result;
      })
      .catch((error) => {
        this.setLoading(false);

        Notify.error("Could not fetch members.");

        return Promise.reject(error);
      });
  }

  fetchGroup(groupObjectId) {
    const method = "get";
    const url = config.BASE_URL + "/parse/classes/Circle";
    const data = {
      where: {
        objectId: groupObjectId,
      },
      include: ["owner", "administrators"],
    };

    this.setLoading(true);

    return parseAction(method, url, data)
      .then((response) => {
        this.setLoading(false);

        return response.results[0];
      })
      .catch((err) => {
        this.setLoading(false);

        Notify.error("Could not fetch group.");

        return Promise.reject(err);
      });
  }

  setMembers(result) {
    const {
      regMembers = [],
      orgMembers = [],
      orgAdmins = [],
      administrators = [],
      owner = {},
      includedInOwnersOrganization = false,
    } = result;

    const groupAdmins = administrators;

    if (includedInOwnersOrganization) {
      /* 
                Displaying members on corporate
                - admins will be the orgAdmins + group.administrators
                - members will be the orgMembers
                - guests will be the regMembers
            */
      const admins = [
        ...orgAdmins,
        // ...groupAdmins,
      ];

      const members = orgMembers;

      const guests = regMembers;

      this.setState({
        admins: this.excludeOwner(admins, owner),
        members: this.excludeOwner(members, owner),
        guests: this.excludeOwner(guests, owner),
      });
    } else {
      /* 
                Displaying members on NON corporate
                - admins will be group.administrators
                - members will be regMembers
                - No guests
            */
      const admins = [...groupAdmins];

      const members = regMembers;

      const guests = [];

      this.setState({
        admins: this.excludeOwner(admins, owner),
        members: this.excludeOwner(members, owner),
        guests,
      });
    }
  }

  excludeOwner(list, owner) {
    return list.filter((user) => user.objectId != owner.objectId);
  }

  setGroup(group = {}) {
    const owner = group.owner || {};

    const currentUser = Parse.User.current();

    const isCreator = owner.objectId === currentUser.id;

    const { administrators = [] } = group;

    const isAdmin = this.isAdmin(currentUser, administrators);

    this.setState({ group, isCreator, isAdmin, owner });
  }

  isAdmin(user, administrators) {
    const adminUser = _.some(administrators, (admin) => {
      return admin.objectId === user.id;
    });

    return adminUser;
  }

  assignAdmin(member) {
    const groupObjectId = this.props.threadId;
    const memberObjectId = member.objectId;

    this.setLoading(true, "Assigning admin...");

    this.props
      .assignAdmin(memberObjectId, groupObjectId)
      .then((circle) => {
        const { members, admins } = this.state;

        const updatedMembers = _.filter(members, (member) => {
          return member.objectId != memberObjectId;
        });

        const updatedAdmins = [
          ...admins,
          {
            ...member,
            isAdministrator: true,
          },
        ];

        this.setState({
          members: updatedMembers,
          admins: updatedAdmins,
        });

        this.setLoading(false);

        Notify.success("Assigned admin successfully!");
      })
      .catch((err) => {
        this.setLoading(false);

        Notify.error("Failed to assign admin.");

        return Promise.reject(err);
      });
  }

  removeAdmin(member) {
    const groupObjectId = this.props.threadId;
    const memberObjectId = member.objectId;

    this.setLoading(true, "Removing admin...");

    this.props
      .removeAdmin(memberObjectId, groupObjectId)
      .then((circle) => {
        const { members, admins } = this.state;

        const updatedAdmins = _.filter(admins, (member) => {
          return member.objectId != memberObjectId;
        });

        const updatedMembers = [
          ...members,
          {
            ...member,
            isAdministrator: false,
          },
        ];

        this.setState({
          members: updatedMembers,
          admins: updatedAdmins,
        });

        this.setLoading(false);

        Notify.success("Admin removed successfully!");
      })
      .catch((err) => {
        this.setLoading(false);

        Notify.error("Failed to remove admin.");

        return Promise.reject(err);
      });
  }

  removeMember(member) {
    const userObjectId = member.objectId;

    if (member.isCorporateMember) {
      const alertContent = (
        <div>
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>Unable to remove corporate member</div>
          <div style={{ fontSize: "14px" }}>Go to corporate settings to make changes</div>
        </div>
      );

      return Notify.info(alertContent);
    }

    this.setLoading(true, "Removing member...");

    this.props
      .removeMember(userObjectId, this.state.group.uuid)
      .then((circleMember) => {
        const { members, admins } = this.state;

        const updatedMembers = members.filter((user) => {
          return user.objectId != userObjectId;
        });

        const updatedAdmins = admins.filter((user) => {
          return user.objectId != userObjectId;
        });

        this.setState({
          members: updatedMembers,
          admins: updatedAdmins,
        });

        this.setLoading(false);

        Notify.success("Member removed successfully!");
      })
      .catch((res) => {
        this.setLoading(false);

        const { responseJSON } = res;

        if (responseJSON) {
          const { error } = responseJSON;

          const alertContent = (
            <div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>Failed to remove guests</div>
              <div style={{ fontSize: "14px" }}>{error.message}</div>
            </div>
          );

          return Notify.error(alertContent);
        }

        return Notify.error("Failed to remove guests.");
      });
  }

  removeGuest(member) {
    const userObjectId = member.objectId;

    if (member.isCorporateMember) {
      const alertContent = (
        <div>
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>Unable to remove corporate member</div>
          <div style={{ fontSize: "14px" }}>Go to corporate settings to make changes</div>
        </div>
      );

      return Notify.info(alertContent);
    }

    this.setLoading(true, "Removing guest...");

    this.props
      .removeMember(userObjectId, this.state.group.uuid)
      .then((circleMember) => {
        const { guests } = this.state;

        const updatedMembers = guests.filter((user) => {
          return user.objectId != userObjectId;
        });

        this.setState({
          guests: updatedMembers,
        });

        this.setLoading(false);

        Notify.success("Member removed successfully!");
      })
      .catch((res) => {
        this.setLoading(false);

        const { responseJSON } = res;

        if (responseJSON) {
          const { error } = responseJSON;

          const alertContent = (
            <div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>Failed to remove guests</div>
              <div style={{ fontSize: "14px" }}>{error.message}</div>
            </div>
          );

          return Notify.error(alertContent);
        }

        return Notify.error("Failed to remove guests.");
      });
  }

  selectTab(currentTab) {
    this.setState({ currentTab });
  }

  inviteMember(toBeInvited) {
    const { group, members } = this.state;

    this.setLoading(true, "Adding members...");

    const promises = toBeInvited.map((contact) => {
      return this.props.inviteMember(contact, group.uuid);
    });

    Promise.all(promises)
      .then((circleMember) => {
        this.setState({
          members: [...members, ...toBeInvited],
        });

        this.props.onNewMember(toBeInvited);

        this.setLoading(false);

        Notify.success("Member added successfully!");
      })
      .catch((res) => {
        this.setLoading(false);

        const { responseJSON } = res;

        if (responseJSON) {
          const { error } = responseJSON;

          const alertContent = (
            <div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>Failed to add member</div>
              <div style={{ fontSize: "14px" }}>{error}</div>
            </div>
          );

          return Notify.error(alertContent);
        }

        return Notify.error("Failed to add member.");
      });
  }

  handleClick(e) {
    this.props.onClose();
  }

  async handleAddGuests(toBeInvited) {
    const { group, guests } = this.state;

    this.setLoading(true, "Adding guests...");

    const promises = toBeInvited.map((contact) => {
      return this.props.inviteMember(contact, group.uuid);
    });

    Promise.all(promises)
      .then((circleMember) => {
        this.setState({
          guests: [...guests, ...toBeInvited],
        });

        this.props.onNewMember(toBeInvited);

        this.setLoading(false);

        Notify.success("Guests added successfully!");
      })
      .catch((res) => {
        this.setLoading(false);

        const { responseJSON } = res;

        if (responseJSON) {
          const { error } = responseJSON;

          const alertContent = (
            <div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>Failed to add guests</div>
              <div style={{ fontSize: "14px" }}>{error}</div>
            </div>
          );

          return Notify.error(alertContent);
        }

        return Notify.error("Failed to add guests.");
      });
  }

  render() {
    const {
      isLoading,
      isCreator,
      isAdmin,
      loadingMessage,
      members,
      admins,
      owner,
      guests,
      currentTab,
      group,
    } = this.state;

    return (
      <div id="memberListModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-full-height">
            <div className="modal-header">
              <h5 className="modal-title hc-primary-text" style={{ fontSize: "1.25rem" }}>
                Members
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body pb-0">
              <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                  <a
                    onClick={() => this.selectTab("list")}
                    className={currentTab === "list" ? "hc-nav-link active" : "hc-nav-link"}
                    href="#"
                  >
                    <i className="fa fa-list mr-2"></i>
                    Member List
                  </a>
                </li>
                {group.includedInOwnersOrganization ? (
                  <li className="nav-item">
                    <a
                      onClick={() => this.selectTab("addGuest")}
                      className={currentTab === "addGuest" ? "hc-nav-link active" : "hc-nav-link"}
                      href="#"
                    >
                      <i className="fa fa-user-plus mr-2"></i>
                      Add guest
                    </a>
                  </li>
                ) : (
                  <li className="nav-item">
                    <a
                      onClick={() => this.selectTab("addMember")}
                      className={currentTab === "addMember" ? "hc-nav-link active" : "hc-nav-link"}
                      href="#"
                    >
                      <i className="fa fa-user-plus mr-2"></i>
                      Add member
                    </a>
                  </li>
                )}
              </ul>
            </div>

            {currentTab === "list" ? (
              <div className="modal-body pt-0" style={{ height: "100%", overflowY: "auto" }}>
                <MemberList
                  isCorporate={group.includedInOwnersOrganization}
                  onClick={(e) => this.handleClick(e)}
                  members={members}
                  admins={admins}
                  owner={owner}
                  guests={guests}
                  isLoading={isLoading}
                  isCreator={isCreator}
                  isAdmin={isAdmin}
                  onAssignAdmin={this.assignAdmin}
                  onRemoveAdmin={this.removeAdmin}
                  onRemoveMember={this.removeMember}
                  onRemoveGuest={this.removeGuest}
                />
              </div>
            ) : (
              ""
            )}

            {currentTab === "addMember" ? <AddMember members={members} inviteMember={this.inviteMember} /> : ""}

            {currentTab === "addGuest" ? (
              <AddGuest
                ownerId={group.owner.objectId}
                excluded={guests}
                onSubmit={(users) => this.handleAddGuests(users)}
              />
            ) : (
              ""
            )}
            <div className="modal-footer rm-border" />
          </div>
        </div>

        <Loader isLoading={isLoading} message={loadingMessage} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // Access values from store
});

export default connect(mapStateToProps, {
  assignAdmin,
  removeAdmin,
  removeMember,
  inviteMember,
  addGuestsToCircle,
})(MemberListModal);
