import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  setActiveTab,
  setAttentionedMessage,
  setActivityList,
  getActivity,
  linkToTask,
  markAsRead,
} from "../../../actions/ThreadAction";
import config from "../../../config";
import { parseAction } from "../../../actions/REST";
import Spinner from "../../Common/Spinner";
import DiscussionModal from "../../Conversation/Setting/DiscussionModal";
import "./index.css";
import moment from "moment";
import PUBNUB from "pubnub";
import Parse from "parse";
import DOMPurify from "dompurify";

const ActivityList = ({ history }) => {
  const dispatch = useDispatch();

  const { activities } = useSelector(mySelector, shallowEqual);

  const [showActivity, setShowActivity] = useState(false);
  const [filter, setFilter] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [groupBy, setGroupBy] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadBadge, setUnreadBadge] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDiscussion, setOpenDiscussion] = useState(false);
  const [actionItem, setActionItem] = useState();

  useEffect(() => {
    dispatch(getActivity()).then((res) => {
      if (res) {
        groupByDate(res);
        setHasMore(res.hasMore);
        res.activity.map((item) => {
          if (item.read === false) setUnreadBadge(true);
        });
      }
    });
    fetchPubNubKey();
  }, []);

  useEffect(() => {
    window.addEventListener("click", function(e) {
      if (document.getElementById("clickbox") && document.getElementById("clickbox").contains(e.target)) {
        // Clicked in box
      } else {
        setShowActivity(false);
      }
    });
  }, []);

  useEffect(() => {
    if (unreadCount === 0) setUnreadBadge(false);
    else setUnreadBadge(true);
  }, [unreadCount]);

  useEffect(() => {
    let count = 0;
    activities.map((item) => {
      if (item.read === false) count++;
    });
    setUnreadCount(count);
  }, [activities]);

  const groupByDate = (act) => {
    let group = [];
    let date = new Date();
    let yesterday = date.setDate(date.getDate() - 1);

    act.activity.map((item, i) => {
      let current = moment(item.createdAt);

      if (current == date) {
        group.push("today");
        group[0] = i;
      } else if (current == yesterday) {
        // console.log("yesterday")
        group[1] = i;
      } else if (current < yesterday) {
        // console.log("older");
      }
    });
  };

  const fetchPubNubKey = () => {
    parseAction("post", config.BASE_URL + "/parse/functions/getPubNubKeys")
      .then((keys) => {
        initializePubNub(keys.result);
      })
      .catch((error) => {
        // TODO handle error when fetching thread
        Parse.User.logOut().then(() => {
          localStorage.setItem("is_logged_in", false);
          window.location.href = "/";
        });
      });
  };

  const initializePubNub = (keys) => {
    const PubNub = new PUBNUB({
      publish_key: keys.publishKey,
      subscribe_key: keys.subscribeKey,
      ssl: window.location.protocol.toLowerCase() === "https:",
      origin: "pubsub.pubnub.com",
      uuid: Parse.User.current().id,
      presenceTimeout: 120,
      heartbeatInterval: 30,
    });

    PubNub.addListener({
      message: (m) => {
        var msg = m.message;
        if (msg.type === "activity_updated") {
          handleSetActivity(msg.activity);
        }
      },
    });
    let channels = ["priv_" + Parse.User.current().id];
    PubNub.subscribe({ channels: channels });
  };

  const markasRead = (activityId) => {
    dispatch(markAsRead([activityId]));
    setUnreadCount(unreadCount - 1);
  };

  const markAllAsRead = () => {
    let activityIDs = activities.map((item) => {
      return item.objectId;
    });
    dispatch(markAsRead(activityIDs));
    setOpenConfirm(false);
    // dispatch(getActivity([], "", 0));
    setShowActivity(true);
    activities.map((item) => {
      item.read = true;
    });
    setUnreadCount(0);
  };

  const handleSetActivity = (activity) => {
    activity.message.threadId = activity.message.circle.objectId;

    if (activity.read === false) {
      dispatch(setActivityList(activity));
      setUnreadBadge(true);
    }
  };

  const openThread = (activity) => {
    let currentId = window.location.pathname.slice(3);
    markasRead(activity.objectId);

    // If currently in thread
    if (currentId !== activity.message.threadId) {
      if (activity.actionItem || activity.activity === "commented on a task") {
        activity.actionItem.threadId = activity.message.threadId;
        setActionItem(activity.actionItem);
        setOpenDiscussion(true);
      } else {
        activity.message.originalMessageDate = {
          iso: activity.createdAt,
        };
        console.log("activity.message", activity.message);
        dispatch(setAttentionedMessage(activity.message));
        dispatch(setActiveTab("message"));
        history.push({
          pathname: "/u/" + activity.message.threadId,
        });
      }
    }

    setShowActivity(false);
  };

  const handleActivityScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    var bottom = Math.round(scrollHeight) - Math.round(scrollTop) === clientHeight;
    if (
      Math.round(scrollHeight) - Math.round(scrollTop) + 1 === clientHeight ||
      Math.round(scrollHeight) - Math.round(scrollTop) - 1 === clientHeight
    )
      bottom = true;
    if (bottom && hasMore) {
      // setIsLoading(true);
      dispatch(getActivity(activities, filter, page + 1)).then((res) => {
        setHasMore(res.hasMore);
      });
      setPage(page + 1);
    }
  };

  const getFilterActivity = (read) => {
    setFilter(read);
    dispatch(getActivity([], read, 0));
  };

  return (
    <div id="clickbox" className="d-flex justify-content-end m-2">
      {openDiscussion && <DiscussionModal isOpen={openDiscussion} modalClose={setOpenDiscussion} item={actionItem} />}
      <div
        className="btn p-0 my-1"
        style={{ position: "relative" }}
        onClick={() => {
          setShowActivity(!showActivity);
        }}
      >
        <i className="fas fa-bell fa-lg" style={{ transform: "rotate(25deg)" }}></i>
        {unreadBadge ? (
          <span
            className="hc-icon-badge"
            style={{
              width: "10px",
              height: "10px",
              right: "-10px",
              top: "0px",
              borderRadius: "50%",
              position: "absolute",
            }}
          >
            {}
          </span>
        ) : (
          ""
        )}
      </div>
      <div
        className={`Tab-Search-Results ${showActivity ? "" : "invisible"}`}
        style={{ height: "600px" }}
        onScroll={handleActivityScroll}
      >
        {false ? (
          <div style={{ height: "70px", marginTop: "25px" }} align="center">
            <Spinner top="50" />
          </div>
        ) : (
          <>
            <div className="d-flex m-2">
              <div>
                <strong>
                  <div>Activities</div>
                </strong>
                <div className="text-xs">{moment(new Date()).format("LL, h:mm a")}</div>
              </div>
              {unreadCount > 0 && (
                <a
                  className="btn text-primary"
                  style={{ marginLeft: "auto" }}
                  onClick={unreadCount > 0 ? () => setOpenConfirm(true) : () => {}}
                >
                  Mark all as read&nbsp;
                  <span className="badge badge-secondary">{unreadCount}</span>
                </a>
              )}
            </div>
            <div
              className="nav nav-bac"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {/* <button
              className={`nav-item ${ filter === "" ? "hc-nav-link-active" : "hc-nav-link" }`}
              style={{ marginRight: 5 }}
              onClick={() => getFilterActivity("")}
            >
              All
            </button>
            <button
              className={`nav-item ${ filter === false ? "hc-nav-link-active" : "hc-nav-link" }`}
              style={{ marginRight: 5 }}
              onClick={() => getFilterActivity(false)}
            >
              Unread
            </button>
            <button
              className={`nav-item ${ filter === true ? "hc-nav-link-active" : "hc-nav-link" }`}
              style={{ marginRight: 5 }}
              onClick={() => getFilterActivity(true)}
            >
              Read
            </button> */}
            </div>
            {activities.map((item) => (
              <>
                <ActivityItem item={item} openThread={() => openThread(item)} />
              </>
            ))}
            {hasMore && (
              <div style={{ height: "40px" }} align="center">
                <Spinner top="50" />
              </div>
            )}
            {!hasMore && (
              <a className="dropdown-item d-flex flex-row item-result">
                <div className="text-secondary"> No more activity...</div>
              </a>
            )}
            {openConfirm && (
              <Confirmation
                markRead={() => markAllAsRead()}
                setShowActivity={setShowActivity}
                onClose={setOpenConfirm}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ActivityItem = ({ item = {}, openThread }) => {
  let content = {};

  if (item.actionItem) {
    switch (item.actionItem.priorityLevel) {
      case 2:
        content.taskPrio = (
          <span className="badge badge-pill badge-light priority-flex" style={{ border: "1px solid #04589e" }}>
            <i className="fad fa-angle-double-up fa-fw" style={{ color: "#04589e" }}></i>
            <span>&nbsp;High Priority</span>
          </span>
        );
        break;
      case 1:
        content.taskPrio = (
          <span className="badge badge-pill badge-light priority-flex" style={{ border: "1px solid #f6c23e" }}>
            <span style={{ color: "#f6c23e" }}> = </span>
            <span>&nbsp;Medium Priority</span>
          </span>
        );
        break;
      case 0:
        content.taskPrio = (
          <span className="badge badge-pill badge-light priority-flex" style={{ border: "1px solid grey" }}>
            <i class="text-primary fad fa-angle-double-down fa-fw"></i>
            <span>&nbsp;Routine Priority</span>
          </span>
        );
        break;
    }
  }

  content.badge = !item.read ? <span className="activity-unread m-1"> </span> : "";

  content.url = item.created_by.picture ? item.created_by.picture.url : require("../../../assets/images/default.png");

  content.main = <span className="font-weight-bold">{item.created_by.displayName}</span>;

  content.sub = <div className="text-secondary text-xs">{item.activity}</div>;

  if (item.message && item.message.circle) {
    content.icon =
      item.message.circle.className === "Circle" ? <i className="fa fa-users"></i> : <i className="fa fa-user"></i>;

    content.source =
      item.message.circle.name.length > 30 ? (
        <span>{(item.message.circle.name.slice(0, 15) + "...").toUpperCase()}</span>
      ) : (
        <span>{item.message.circle.name.toUpperCase()}</span>
      );

    const cleanHTML = DOMPurify.sanitize(item.message.text);
    const tempDiv = document.createElement("span");
    tempDiv.innerHTML = cleanHTML;
    const cleanText = tempDiv.innerText;

    let textTrunc = cleanText.length > 40 ? cleanText.slice(0, 40) + "..." : cleanText;
    switch (item.activity) {
      case "called your attention in a message.":
        content.text = (
          <div title={item.message.text.length > 40 ? cleanText : ""}>
            <i className="fa fa-comment fa-sm"></i>
            &nbsp;&nbsp;{textTrunc}
          </div>
        );
        break;
      case "creates a task for you.":
        content.text = (
          <div title={item.message.text.length > 40 ? cleanText : ""}>
            <i className="fa fa-list fa-sm"></i>
            &nbsp;&nbsp;{textTrunc}
          </div>
        );
        break;
      case "sent an attention message to you":
        content.text = (
          <div title={item.message.text.length > 40 ? cleanText : ""}>
            <i className="fa fa-comment fa-sm"></i>
            &nbsp;&nbsp;{textTrunc}
          </div>
        );
        break;
      case "sent you an urgent message.":
        content.text = (
          <div title={item.message.text.length > 40 ? cleanText : ""}>
            <i className="fa fa-fw fa-exclamation-circle" style={{ color: item.read ? "grey" : "red" }}></i>
            &nbsp;&nbsp;{textTrunc}
          </div>
        );
        break;
      case "commented on a task":
        content.text = (
          <div title={item.message.text.length > 40 ? cleanText : ""}>
            <i className="fas fa-reply" style={{ transform: "rotate(180deg)" }}></i>
            &nbsp;&nbsp;{textTrunc}
          </div>
        );
        break;
    }
  } else {
    // missing item.message case
    content.icon = <i className="fa fa-user"></i>;
    content.source = "";
    content.text = "";
  }

  return (
    <a
      className="dropdown-item d-flex flex-row item-result"
      href="#"
      style={{ border: "1px solid rgb(229, 229, 229)", backgroundColor: `${item.read ? "#F2F2F2" : ""}` }}
      onMouseDown={() => {
        openThread(item);
        item.read = true;
      }}
    >
      <div className="m-1">
        {content.badge}
        <img src={content.url} style={{ height: "35px", width: "35px" }} />
      </div>
      <div>
        <div className="" style={{ margin: "5px 0px" }}>
          {content.main} {content.icon} {content.source}
        </div>
        {content.sub}
        {content.taskPrio ? content.taskPrio : ""}
        {content.text}
      </div>
      <div className="text-secondary text-xs" style={{ marginLeft: "auto", marginTop: "5%" }}>
        <span>{moment(item.createdAt).format("MMM D, h:mm a")}</span>
      </div>
    </a>
  );
};

const Confirmation = ({ markRead, setShowActivity, onClose = () => {} }) => {
  return (
    <div className="assignment-notify-container">
      <div className="attention-notify-box">
        <i className="fas fa-exclamation-circle hc-primary-text"></i>
        <div className="save-changes-flex" style={{ marginLeft: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h6>Mark all "unread" notification ?</h6>
          </div>
          <div style={{ textAlign: "right", marginTop: 10 }}>
            <button
              type="button btn-sm"
              style={{ marginRight: "10px" }}
              className="btn btn-hubchart btn-hubchart-primary"
              onClick={() => markRead()}
            >
              Mark as read
            </button>
            <button type="button btn-sm" className="btn btn-secondary" onClick={() => onClose(false)}>
              Cancel
            </button>
          </div>
        </div>
        <i
          className="fas fa-times"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setShowActivity(true);
            onClose(false);
          }}
        />
      </div>
    </div>
  );
};

const mySelector = (state) => ({
  activities: state.thread.activities,
});

export default ActivityList;
