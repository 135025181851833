import React, { Component, Fragment } from "react";
import Parse from "parse";
import moment from "moment";
import { connect } from "react-redux";
import { parseAction } from "../../actions/REST";
import MessageApi from "../../api/Message";
import { Document, Page } from "react-pdf";
import config from "../../config";
import _ from "lodash";
import util from "../../helper/util";
import pdfLogo from "../../assets/images/pdf.png";

import PatientDiagnosisModal from "./Setting/PatientDiagnosisModal";
import PatientOrdersModal from "./Setting/PatientOrdersModal";
import PatientProblemsModal from "./Setting/PatientProblemsModal";
import PrescriptionModal from "./Setting/PrescriptionModal";
import ProgressNotesModal from "./Setting/ProgressNotesModal";
import UpdateConferenceModal from "../TabList/ConferenceTab/UpdateConferenceModal";
import ForwardModal from "../TabList/ConferenceTab/ForwardConferenceModal";
import ActionsModal from "./Setting/ActionsModal";
import TabLoader from "../Common/TabLoader";
import Notify from "../Common/Notify";
import ConferenceItem from "../TabList/ConferenceTab/ConferenceItem";
import { getConferenceCallsById } from "../../actions/ConferenceAction";
import { getProgressNotes } from "../../actions/ProgressNotesAction";
import { fetchSelectedThread } from "../../actions/ThreadAction";
import {
  getPatientOrdersHistory,
  getPrescriptionsHistory,
  getGroupedLists,
  fetchPatientMedications,
  fetchPatientProblems,
  fetchPatientDiagnoses,
} from "../../actions/PatientAction";
import { getConferenceText } from "../../helper/util";

import "../../../node_modules/emoji-mart/css/emoji-mart.css";
import ImageLoad from "../Common/ImageLoad";
import { pdf } from "@react-pdf/renderer";
import ImagePlaceholder from "../Common/ImagePlaceholder";
import ListModal from "./Setting/ListModal";
import SideTabNav from "./SideTabNav";
import SideTabList from "./SideTabList";
import ItemSearch from "../TabList/ItemSearch";
const $ = window.$;

export class SideTab extends Component {
  constructor() {
    super();
    this.state = {
      actionItemsModalShown: false,
      rxModalShown: false,
      dxModalShown: false,
      problemModalShown: false,
      documentsModalShown: false,
      progressNotesModalShown: false,
      sideTabLoading: false,
      showMoreActionItems: false,
      showMoreMedications: false,
      showMoreDiagnoses: false,
      showMoreProblems: false,
      showMoreConferences: false,
      mobileCardsOpened: false,
      updateNotesModalShown: false,
      forwardModalShown: false,
      removeHideCards: false,
      showMedicinesDetails: false,
      showOrdersDetails: false,
      showDiagnosesDetails: false,
      showActionItemsDetails: false,
      showProblemsDetails: false,
      showGroupedListDetails: false,
      showProgressNotesDetails: false,
      showRxHistoryDetails: false,
      showOrdersHistoryDetails: false,
      showFloatingAction: true,
      itemsViewed: false,
      scrollBottom: false,
      detailsTitle: "",
      messageToForward: {},
      selectedConference: {},
      user: {},
      medicationsLength: 0,
      resizeCount: 0,
      pendingItemsCount: 0,
      actionItems: [],
      medications: [],
      orders: [],
      diagnoses: [],
      problems: [],
      documents: [],
    };

    this.showHideActionItemsModal = this.showHideActionItemsModal.bind(this);
    this.showHideRxModal = this.showHideRxModal.bind(this);
    this.showHideProblemModal = this.showHideProblemModal.bind(this);
    this.showHideOrdersModal = this.showHideOrdersModal.bind(this);
    this.showHideListModal = this.showHideListModal.bind(this);
    this.showHideDxModal = this.showHideDxModal.bind(this);
    this.showHidePNModal = this.showHidePNModal.bind(this);
    this.fetchPatientInfo = this.fetchPatientInfo.bind(this);
    this.sortActionItems = this.sortActionItems.bind(this);
    this.openFilesWindow = this.openFilesWindow.bind(this);
    this.openProgressNotes = this.openProgressNotes.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this);
    this.getThreadName = this.getThreadName.bind(this);
    this.initializeData = this.initializeData.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.getFileType = this.getFileType.bind(this);
    this.handleRichTextData = this.handleRichTextData.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.handleShowMoreData = this.handleShowMoreData.bind(this);
    this.handleMedsLength = this.handleMedsLength.bind(this);
    this.handleRichTextDataLength = this.handleRichTextDataLength.bind(this);
    this.showHideCards = this.showHideCards.bind(this);
    this.setResizeCount = this.setResizeCount.bind(this);
    this.fetchConferenceCallsById = this.fetchConferenceCallsById.bind(this);
    this.fetchProgressNotes = this.fetchProgressNotes.bind(this);
    this.handleFetchPatientMedications = this.handleFetchPatientMedications.bind(
      this
    );
    this.handleFetchPatientProblems = this.handleFetchPatientProblems.bind(
      this
    );
    this.handleFetchPatientDiagnoses = this.handleFetchPatientDiagnoses.bind(
      this
    );
    this.fetchPatientOrdersHistory = this.fetchPatientOrdersHistory.bind(this);
    this.fetchPrescriptionsHistory = this.fetchPrescriptionsHistory.bind(this);
    this.fetchGroupedLists = this.fetchGroupedLists.bind(this);
    this.handleOpenUpdateNotesModal = this.handleOpenUpdateNotesModal.bind(
      this
    );
    this.handleOpenForwardModal = this.handleOpenForwardModal.bind(this);
    this.handleSetUpdateNotesModal = this.handleSetUpdateNotesModal.bind(this);
    this.openCardDetails = this.openCardDetails.bind(this);
    this.openActionItems = this.openActionItems.bind(this);
    this.closeActionItems = this.closeActionItems.bind(this);
    this.openProgressNote = this.openProgressNote.bind(this);
    this.openOrderHistory = this.openOrderHistory.bind(this);
    this.copyOrderLink = this.copyOrderLink.bind(this);
    this.openPrescriptionHistory = this.openPrescriptionHistory.bind(this);
    this.copyPrescriptionLink = this.copyPrescriptionLink.bind(this);
    this.countPendingItems = this.countPendingItems.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  showHideActionItemsModal(bool) {
    const { user = {} } = this.state;
    localStorage.setItem(`${user.objectId}_items_viewed`, true);
    this.setState({ actionItemsModalShown: bool });
  }

  showHideRxModal(bool) {
    this.setState({ rxModalShown: bool });
  }

  showHideOrdersModal(bool) {
    this.setState({ ordersModalShown: bool });
  }

  showHideDxModal(bool) {
    this.setState({ dxModalShown: bool });
  }

  showHideProblemModal(bool) {
    this.setState({ problemModalShown: bool });
  }

  showHideListModal(bool) {
    this.setState({ listModalShown: bool });
  }

  showHidePNModal(bool) {
    this.setState({ progressNotesModalShown: bool });
  }

  handleShowMoreData(stateKey, currentValue) {
    this.setState({
      showMoreActionItems: false,
      showMoreMedications: false,
      showMoreDiagnoses: false,
      showMoreProblems: false,
      showMoreConferences: false,
      [stateKey]: !currentValue,
    });
  }

  showHideCards(bool) {
    this.setState({
      mobileCardsOpened: bool,
    });
  }

  setResizeCount(resizeCount) {
    this.setState({ resizeCount });
  }

  async getFiles(threadId) {
    this.setState({ isLoading: true });

    try {
      const result = await MessageApi.getFilesv2(threadId, {
        page: 0,
        size: 9,
      });

      const documents = result.files.map((file) => {
        const fileType = util.detectType(file.fileType);

        return {
          ...file,
          fileType,
        };
      });

      this.setState({ documents, isLoading: false });
    } catch (err) {
      console.error(err);
      this.setState({ isLoading: false });
    }
  }

  openCardDetails(e, { title = "", state = "" }) {
    e.stopPropagation();
    document
      .getElementById("cards-list-container")
      .setAttribute(
        "style",
        "min-width: 0px !important;"
      );
    this.setState({
      showFloatingAction: false,
      removeHideCards: true,
      showMedicinesDetails: false,
      showOrdersDetails: false,
      showDiagnosesDetails: false,
      showActionItemsDetails: false,
      showProblemsDetails: false,
      showGroupedListDetails: false,
      showProgressNotesDetails: false,
      showRxHistoryDetails: false,
      showOrdersHistoryDetails: false,
      detailsTitle: title,
      [state]: true,
    });
  }

  closeCardDetails() {
    document
      .getElementById("cards-list-container")
      .setAttribute(
        "style",
        "min-width: 344px !important; width: 344px !important;"
      );
    this.setState({
      showFloatingAction: true,
      removeHideCards: false,
      showMedicinesDetails: false,
      showOrdersDetails: false,
      showDiagnosesDetails: false,
      showActionItemsDetails: false,
      showProblemsDetails: false,
      showGroupedListDetails: false,
      showProgressNotesDetails: false,
      showRxHistoryDetails: false,
      showOrdersHistoryDetails: false,
      detailsTitle: "",
    });
  }

  openActionItems() {
    const { actionItems = [] } = this.state;
    const conHeight =
      actionItems.length > 0 ? `${actionItems.length * 30 + 30}px` : "120px";

    document.getElementById("action-items-container").setAttribute(
      "style",
      `
        position: absolute;
        height: 100%;
        width: inherit;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
      `
    );

    document.getElementById("card-item-container-id").setAttribute(
      "style",
      `
        width: 320px; 
        height: ${conHeight}; 
        transition: height .3s ease-in .3s, width .3s; 
        right: 10px;
      `
    );

    document.getElementById("action-items-title").setAttribute(
      "style",
      `
        border-bottom-left-radius: 0px; 
        border-bottom-right-radius: 0px; 
        transition: border-bottom-left-radius .3s, border-bottom-right-radius .3s;
        transition-delay: .3s;
      `
    );

    document
      .getElementById("action-items-button")
      .setAttribute("style", "width: 0px; display: none;, visibility: hidden;");
  }

  closeActionItems() {
    document
      .getElementById("card-item-container-id")
      .setAttribute(
        "style",
        `width: 120px; height: 31px; transition: width .3s ease-in .3s, height .3s; right: 10px;`
      );

    document.getElementById("action-items-title").setAttribute(
      "style",
      `
        border-bottom-left-radius: 15px; 
        border-bottom-right-radius: 15px; 
        transition: border-bottom-left-radius .3s, border-bottom-right-radius .3s;
      `
    );
    setTimeout(() => {
      document
        .getElementById("card-item-container-id")
        .setAttribute("style", `right: 15px;`);

      document.getElementById("action-items-container").setAttribute(
        "style",
        `
        position: unset;
        height: 0px;
        width: 0px;
        bottom: unset;
        background: none;
      `
      );

      document
        .getElementById("action-items-button")
        .setAttribute("style", "width: 120px; display: unset;");
    }, 600);
  }

  async fetchPatientInfo(threadId, groupType) {
    const { patientInfoReduced } = this.props;
    // const url = config.BASE_URL + "/parse/classes/Circle";
    // const params = {
    //   where: {
    //     objectId: threadId,
    //   },
    //   include: ["actionItems", "actionItems.assignedTo", "members"],
    // };
    // const res = await parseAction("get", url, params);
    // const data = res.results[0];
    // const patientView = JSON.parse(localStorage.getItem(`${data.objectId}_patient_items_view`)) || {};
    // const lsPatientView = {
    //   date: moment(new Date()).format("l"),
    // };

    // this.setState(
    //   {
    //     user: data,
    //   },
    //   () => {
    //     if (
    //       patientView.date !== moment(new Date()).format("l") &&
    //       lsPatientView.itemsCount > 0 &&
    //       groupType === "patient"
    //     ) {
    //       localStorage.setItem(`${data.objectId}_patient_items_view`, JSON.stringify(lsPatientView));
    //       this.showHideActionItemsModal(true);
    //     } else if (groupType === "patient" && !patientView.date) {
    //       localStorage.setItem(`${data.objectId}_patient_items_view`, JSON.stringify(lsPatientView));
    //     }
    //   }
    // );
  }

  async fetchConferenceCallsById(id) {
    const { getConferenceCallsById: getConferences } = this.props;
    return getConferences(id);
  }

  async fetchProgressNotes(id) {
    const { getProgressNotes } = this.props;
    return getProgressNotes(id, 0, [], 50);
  }

  async handleFetchPatientMedications(id) {
    const { fetchPatientMedications } = this.props;
    return fetchPatientMedications(id);
  }

  async handleFetchPatientProblems(id) {
    const { fetchPatientProblems } = this.props;
    return fetchPatientProblems(id);
  }

  async handleFetchPatientDiagnoses(id) {
    const { fetchPatientDiagnoses } = this.props;
    return fetchPatientDiagnoses(id);
  }

  async fetchPatientOrdersHistory(id) {
    const { getPatientOrdersHistory: getOrdersHistory } = this.props;
    return getOrdersHistory(id);
  }

  async fetchPrescriptionsHistory(id) {
    const { getPrescriptionsHistory } = this.props;
    return getPrescriptionsHistory(id);
  }

  async fetchGroupedLists(id) {
    const { getGroupedLists } = this.props;
    return getGroupedLists(id);
  }

  async getSelectedThread(id) {
    const { fetchSelectedThread } = this.props;
    return fetchSelectedThread(id);
  }

  sortActionItems(actionItems) {
    const sorted = _.orderBy(
      actionItems,
      [(obj) => new Date(obj.createdAt)],
      ["desc"]
    );
    return sorted;
  }

  openFilesWindow() {
    const { activeThread } = this.props;
    const imageUrl = this.getImageUrl(activeThread);
    const threadName = this.getThreadName(activeThread);
    const filesUrl = [
      window.location.origin,
      "/files",
      "/" + activeThread.threadId,
      "?imageUrl=" + window.encodeURIComponent(imageUrl),
      "&threadName=" + window.encodeURIComponent(threadName),
    ].join("");
    window.open(filesUrl, "Hubchart Files");
  }

  openProgressNotes() {
    const { activeThread } = this.props;
    const url = [
      window.location.origin, 
      "/progressnotes",
      "/" + activeThread.threadId,
      ,
    ].join("");
    // window.open(url, "test");

    window.open(url, activeThread.groupName, "fullscreen=yes");
  }

  getImageUrl(activeThread) {
    let Image;
    switch (activeThread.threadType) {
      case "private":
        Image = activeThread.partnerImageURL;
        break;
      case "group":
        Image = activeThread.groupImageURL;
        break;
      default:
        Image = "";
    }
    return Image;
  }

  openProgressNote(noteId, param = "") {
    const { activeThread } = this.props;
    const filesUrl = [
      window.location.origin,
      "/progressnotes/" + activeThread.threadId,
      "/view/" + noteId,
      param,
    ].join("");

    window.open(filesUrl, "Hubchart Files", "_blank");
  }

  openOrderHistory(orderId, param = "") {
    const { activeThread } = this.props;
    const filesUrl = [
      window.location.origin,
      "/orders-history/" + activeThread.threadId,
      "/" + orderId,
      param,
    ].join("");

    window.open(filesUrl, "Hubchart Orders", "_blank");
  }

  copyOrderLink(orderHistoryId) {
    const { activeThread } = this.props;
    const OrderUrl = `${window.location.origin}/orders-history/${activeThread.threadId}/${orderHistoryId}`;
    const elem = document.createElement("textarea");

    elem.value = OrderUrl;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    Notify.success("Order link copied!");
  }

  openPrescriptionHistory(prescriptionId, param = "") {
    const { activeThread } = this.props;
    const filesUrl = [
      window.location.origin,
      "/prescription-history/" + activeThread.threadId,
      "/" + prescriptionId,
      param,
    ].join("");

    window.open(filesUrl, "Hubchart Prescription", "_blank");
  }

  copyPrescriptionLink(prescriptionHistoryId) {
    const { activeThread } = this.props;
    const OrderUrl = `${window.location.origin}/prescription-history/${activeThread.threadId}/${prescriptionHistoryId}`;
    const elem = document.createElement("textarea");

    elem.value = OrderUrl;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    Notify.success("Prescription link copied!");
  }

  getThreadName(activeThread) {
    let Name;
    switch (activeThread.threadType) {
      case "private":
        Name = activeThread.partnerName;
        break;
      case "group":
        Name = activeThread.groupName;
        break;
      case "adhoc":
        Name = "Private Group";
        break;
      default:
        Name = "";
    }
    return Name;
  }

  getFileType(message) {
    if (message.video) {
      return "video";
    } else if (message.picture) {
      const { url } = message.picture;
      const fileExt = util.getFileExtension(url);
      const isPDF = fileExt === "pdf";
      return isPDF ? "pdf" : "image";
    } else {
      return "image";
    }
  }

  handleRichTextData(richText) {
    const richTextArray = [];

    for (let i = 0; i < (richText.blocks || []).length; i++) {
      if (
        richText.blocks[i].type === "unstyled" &&
        i === 0 &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
      if (
        richText.blocks[i].type === "unstyled" &&
        (richText.blocks[i - 1] || {}).text === "" &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
      if (
        (richText.blocks[i].type === "unordered-list-item" ||
          richText.blocks[i].type === "ordered-list-item") &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
    }

    return richTextArray.map((item) => (
      <div className="card-item" key={item}>
        <div title={item}>{item}</div>
      </div>
    ));
  }

  handleRichTextArray(array) {
    const newArray = array.filter(Boolean);
    const textArray = [];

    for (let i = 0; i < (newArray || []).length; i++) {
      let txt = newArray[i].split("\n");
      textArray.push(txt[0]);
    }

    return textArray.map((item) => (
      <div className="card-item" key={item}>
        <div title={item}>{item}</div>
      </div>
    ));
  }

  handleRichTextDataLength(richText) {
    const richTextArray = [];

    for (let i = 0; i < (richText.blocks || []).length; i++) {
      if (
        richText.blocks[i].type === "unstyled" &&
        i === 0 &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
      if (
        richText.blocks[i].type === "unstyled" &&
        (richText.blocks[i - 1] || {}).text === "" &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
      if (
        (richText.blocks[i].type === "unordered-list-item" ||
          richText.blocks[i].type === "ordered-list-item") &&
        richText.blocks[i].depth === 0
      ) {
        richTextArray.push(richText.blocks[i].text);
      }
    }

    return richTextArray.length;
  }

  handleMedsLength(medications) {
    let count = 0;

    for (let i = 0; i < medications.length; i++) {
      if (medications[i].richText) {
        count = count + this.handleRichTextDataLength(medications[i].richText);
      } else if (medications[i].textArray) {
        count = count + (medications[i].textArray || []).length;
      } else {
        count++;
      }
    }
    return count;
  }

  handleOpenUpdateNotesModal(conference) {
    this.setState({
      selectedConference: conference,
      updateNotesModalShown: true,
    });
  }

  handleSetUpdateNotesModal(isUpdated = false) {
    const { getConferenceCallsById, activeThread } = this.props;
    if (isUpdated) {
      getConferenceCallsById(activeThread.threadId);
    }
  }

  handleOpenForwardModal(conference) {
    const currentUser = Parse.User.current();
    const user = {
      ...currentUser,
      objectId: currentUser.id,
    };
    const text = getConferenceText(conference);
    const message = {
      text,
      user,
    };

    this.setState({
      messageToForward: message,
      selectedConference: conference,
      forwardModalShown: true,
    });
  }

  countPendingItems(items = []) {
    const pendingItems = items.filter((item) => item.status === "PENDING");
    return pendingItems.length;
  }

  refreshData(activeThread) {
    this.initializeData(activeThread);
  }

  initializeData(activeThread = {}) {
    if (!activeThread.objectId) return;
    // this.setState({ sideTabLoading: true });

    this.getFiles(activeThread.threadId);
    this.fetchPatientInfo(activeThread.threadId, activeThread.groupType);
    this.getSelectedThread(activeThread.threadId);
    // Promise.all([
    //   this.fetchConferenceCallsById(activeThread.threadId),
    // ])
    //   .then(() => this.setState({ sideTabLoading: false }))
    //   .catch(() => this.setState({ sideTabLoading: false }));
  }

  handleTabSelect(tab) {
    switch (tab) {
      case "EMR":
        return;
      case "FILES":
        return;
    }
  }

  handleScroll(event) {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    var bottom = Math.round(scrollHeight) - Math.round(scrollTop) === clientHeight;
    if (
      Math.round(scrollHeight) - Math.round(scrollTop) + 1 === clientHeight ||
      Math.round(scrollHeight) - Math.round(scrollTop) - 1 === clientHeight
    )
      bottom = true;
    if (bottom) {
      this.setState({ scrollBottom: true });
    } else {
      this.setState({ scrollBottom: false });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeThread, searchMessages } = nextProps;
    if (activeThread.threadId != this.props.activeThread.threadId) {
      this.initializeData(activeThread);
    }
    if (Object.keys(searchMessages).length > 0) {
      this.showHideCards(true);
    }
  }

  componentDidMount() {
    this.initializeData(this.props.activeThread);
    const dis = this;
    window.addEventListener("resize", () => {
      const { resizeCount } = dis.state;
      if (window.innerWidth < 1154 && resizeCount === 0) {
        dis.showHideCards(true);
        dis.setResizeCount(1);
      }

      if (window.innerWidth > 1154 && resizeCount === 1) {
        dis.showHideCards(false);
        dis.setResizeCount(0);
      }
    });
  }

  render() {
    const {
      actionItemsModalShown = false,
      rxModalShown = false,
      ordersModalShown = false,
      dxModalShown = false,
      problemModalShown = false,
      progressNotesModalShown = false,
      listModalShown = false,
      sideTabLoading = false,
      mobileCardsOpened = false,
      forwardModalShown = false,
      updateNotesModalShown = false,
      showMedicinesDetails = false,
      showOrdersDetails = false,
      showDiagnosesDetails = false,
      showProblemsDetails = false,
      showGroupedListDetails = false,
      showProgressNotesDetails = false,
      showRxHistoryDetails = false,
      showOrdersHistoryDetails = false,
      removeHideCards = false,
      detailsTitle = "",
      messageToForward = {},
      selectedConference = {},
      documents = [],
    } = this.state;
    const {
      activeThread,
      activeTab,
      threadType,
      isPatient,
      progressNotes = [],
      patientMedications = [],
      patientProblems = [],
      patientDiagnoses = [],
      patientOrdersHistory = [],
      prescriptionsHistory = [],
      groupedLists = [],
      notesLoading = false,
      rxLoading = false,
      ordersLoading = false,
      groupListLoading = false,
      patientMedicationsLoading = false,
      patientProblemsLoading = false,
      patientDiagnosesLoading = false,
      searchMessages,
    } = this.props;

    const displayMedDx = threadType === "group" && isPatient ? true : false;
    const medicationsLength = this.handleMedsLength(patientMedications);

    return (
      <Fragment>
        <div
          className="side-tab-mobile"
          onClick={() => this.showHideCards(true)}
        >
          <i className="fas fa-layer-group" />
          <span>Show Cards</span>
        </div>

        {!sideTabLoading && (
          <div
            className="side-tab"
            style={{
              width:
                mobileCardsOpened || window.innerWidth > 1154 ? "350px" : "0px",
            }}
          >
            {(!removeHideCards && window.innerWidth < 1154) && (
              <div className="side-tab-mobile-close-container">
                {mobileCardsOpened && (
                  <div
                    className="side-tab-mobile-close"
                    onClick={() => this.showHideCards(false)}
                  >
                    <i
                      className="fal fa-times"
                      style={{ fontWeight: "bold" }}
                    />
                    &nbsp;&nbsp;Hide Cards
                  </div>
                )}
              </div>
            )}
            <div id="cards-list-container"
              onScroll={this.handleScroll.bind(this)}  
            >
              {(Object.keys(searchMessages).length > 0 ||
                this.props.isSearching) && 
                (
                <div className="card-container" style={{ border: "0px"}}>
                  <div className="card-title-container">
                    <span className="text-grey">
                      Search results
                    </span>
                  </div>
                  <hr style={{margin: "0px"}} />
                  <ItemSearch
                    type="message"
                    setActiveThread={this.props.setActiveThread}
                    activeThread={this.props.activeThread}
                    history={this.props.history}
                    scrollBottom={this.state.scrollBottom}
                    isSearching={this.props.isSearching}
                  />
                </div>
              )}
              {(Object.keys(searchMessages).length == 0 &&
                !this.props.isSearching) && (
                <>
                  <div style={{ display: detailsTitle ? "none" : "block"}}>
                    {displayMedDx && (
                      <Fragment>
                        <div className="card-container">
                          <div className="card-body card-custom">
                            <div
                              className="card-title-container"
                              onClick={() => this.showHideRxModal(true)}
                            >
                              <span className="card-title">
                                Medications&nbsp;&nbsp;
                                <i className="fas fa-prescription"></i>
                              </span>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm more-d"
                                onClick={(e) => {
                                  this.openCardDetails(e, {
                                    title: "Medications",
                                    state: "showMedicinesDetails",
                                  });
                                  this.handleFetchPatientMedications(
                                    activeThread.threadId
                                  );
                                }}
                              >
                                <span>View</span>
                                <i className="far fa-chevron-right"></i>
                              </button>{" "}
                              {/*this.showHideRxModal(true)*/}
                            </div>
                          </div>
                        </div>
                        <div className="card-container">
                          <div className="card-body card-custom">
                            <div
                              className="card-title-container"
                              onClick={() => this.showHideProblemModal(true)}
                            >
                              <span className="card-title">
                                Problems&nbsp;&nbsp;
                                <i className="fas fa-head-side-cough"></i>
                              </span>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm more-d"
                                onClick={(e) => {
                                  this.openCardDetails(e, {
                                    title: "Problems",
                                    state: "showProblemsDetails",
                                  });
                                  this.handleFetchPatientProblems(
                                    activeThread.threadId
                                  );
                                }}
                              >
                                <span>View</span>
                                <i className="far fa-chevron-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="card-container">
                          <div className="card-body card-custom">
                            <div
                              className="card-title-container"
                              onClick={() => this.showHideDxModal(true)}
                            >
                              <span className="card-title">
                                Diagnoses List&nbsp;&nbsp;
                                <i className="fas fa-diagnoses"></i>
                              </span>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm more-d"
                                onClick={(e) => {
                                  this.openCardDetails(e, {
                                    title: "Diagnoses",
                                    state: "showDiagnosesDetails",
                                  });
                                  this.handleFetchPatientDiagnoses(
                                    activeThread.threadId
                                  );
                                }}
                              >
                                <span>View</span>
                                <i className="far fa-chevron-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    <div className="card-container">
                    <div className="card-body card-custom">
                      <div
                        className="card-title-container"
                        // onClick={() => this.openProgressNotes()}
                        onClick={() => this.showHidePNModal(true)}
                      >
                        <span className="card-title">
                          Progress Notes&nbsp;&nbsp;
                          <i className="fas fa-file-signature"></i>
                        </span>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm more-d"
                          onClick={(e) => {
                            this.openCardDetails(e, {
                              title: "Progress Notes",
                              state: "showProgressNotesDetails",
                            });
                            this.fetchProgressNotes(activeThread.threadId);
                          }}
                        >
                          <span>View</span>
                          <i className="far fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
    
                  {/* <div className="card-container">
                    <div className="card-body card-custom">
                      <div className="card-title-container" onClick={() => this.showHideActionItemsModal(true)}>
                        <span className="card-title">
                          Action Items&nbsp;&nbsp;<i className="fas fa-tasks"></i>
                        </span>
                        {pendingItemsCount > 0 && <span class="badge badge-danger badge-notif">{pendingItemsCount}</span>}
                        <button
                          type="button"
                          className="btn btn-primary btn-sm more-d"
                          onClick={(e) =>
                            this.openCardDetails(e, {
                              title: "Action Items",
                              state: "showActionItemsDetails",
                            })
                          }
                        >
                          <span>View</span>
                          <i className="far fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </div> */}
    
                  <div className="card-container">
                    <div className="card-body card-custom">
                      <div className="card-title-container">
                        <span className="card-title">Prescription History</span>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm more-d"
                          onClick={(e) => {
                            this.openCardDetails(e, {
                              title: "Prescription History",
                              state: "showRxHistoryDetails",
                            });
                            this.fetchPrescriptionsHistory(activeThread.threadId);
                          }}
                        >
                          <span>View</span>
                          <i className="far fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-container">
                    <div className="card-body card-custom">
                      <div className="card-title-container">
                        <span
                          className="card-title"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.showHideOrdersModal(true);
                          }}
                        >
                          Orders History
                        </span>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm more-d"
                          style={{ fontWeight: "bold" }}
                          onClick={(e) => {
                            this.openCardDetails(e, {
                              title: "Orders History",
                              state: "showOrdersHistoryDetails",
                            });
                            this.fetchPatientOrdersHistory(activeThread.threadId);
                          }}
                        >
                          <span>View</span>
                          <i className="far fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  {displayMedDx && (
                    <Fragment>
                      <div className="card-container">
                        <div className="card-body card-custom">
                          <div
                            className="card-title-container"
                            onClick={() => this.showHideListModal(true)}
                          >
                            <span className="card-title">
                              Lists and Tables&nbsp;&nbsp;
                              <i className="fas fa-head-side-cough"></i>
                            </span>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm more-d"
                              onClick={(e) => {
                                this.openCardDetails(e, {
                                  title: "Lists and Tables",
                                  state: "showGroupedListDetails",
                                });
                                this.fetchGroupedLists(activeThread.threadId);
                              }}
                            >
                              <span>View</span>
                              <i className="far fa-chevron-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                  <div className="card-container">
                    <div className="card-body card-custom">
                      <div className="card-title-container">
                        <span className="card-title">
                          Documents&nbsp;&nbsp;<i className="fas fa-inbox"></i>
                        </span>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => this.openFilesWindow()}
                        >
                          Manage
                        </button>
                      </div>
                      <hr />
                      <div
                        className="doc-thumbnails"
                        onClick={() => this.openFilesWindow()}
                      >
                        {documents.map((document) => {
                          const {
                            fileType = "",
                            picture = {},
                            thumbnail = {},
                          } = document;
                          const isPDF = fileType === "pdf" ? true : false;
                          const imgUrl =
                            (picture || {}).url || (thumbnail || {}).url || pdfLogo;
                          const thumbStyle = {
                            maxHeight: "90px",
                            maxWidth: "90px",
                          };
    
                          if (isPDF) {
                            const thumbUrl = util.getPdfThumbnail(
                              document.thumbnail
                            );
    
                            return (
                              <div className="doc-item position-relative">
                                <ImageLoad imageURL={thumbUrl} iconSize="2x" />
                                <Hoverlay />
                              </div>
                            );
                          } else {
                            return (
                              <div className="doc-item position-relative">
                                <ImageLoad imageURL={imgUrl} iconSize="2x" />
                                <Hoverlay />
                              </div>
                            );
                          }
                        })}
                        {documents.length === 0 && (
                          <div className="em-container">
                            <em>No Documents Found</em>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-container">
                    <div className="card-body card-custom">
                      <div className="card-title-container">
                        <span className="card-title">
                          Telemed Logs/Notes&nbsp;&nbsp;<i className="fas fa-sticky-note"></i>
                        </span>
                      </div>
                      <hr />
                      <div className="card-item-container-2">
                        {!showMoreConferences
                          ? initial_conference.map((conf) => (
                              <ConferenceItem
                                key={conf.objectId}
                                conference={conf}
                                onOpenUpdateModal={() => this.handleOpenUpdateNotesModal(conf)}
                                onOpenForwardModal={() => this.handleOpenForwardModal(conf)}
                              />
                            ))
                          : conferences.map((conf) => (
                              <ConferenceItem
                                key={conf.objectId}
                                conference={conf}
                                onOpenUpdateModal={() => this.handleOpenUpdateNotesModal(conf)}
                                onOpenForwardModal={() => this.handleOpenForwardModal(conf)}
                              />
                            ))}
                        {conferences.length === 0 && (
                          <div className="em-container">
                            <em>No Telemed Logs/Notes Found</em>
                          </div>
                        )}
                      </div>
                      {conferences.length > 1 && (
                        <Fragment>
                          <hr />
                          <div className="show-more">
                            <a
                              className="btn btn-link btn-sm"
                              type="button"
                              onClick={() => this.handleShowMoreData("showMoreConferences", showMoreConferences)}
                            >
                              {showMoreConferences ? "Show Less" : "Show More"}
                            </a>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div> */}
                  {/* {showFloatingAction && (
                    <Fragment>
                      <div id="action-items-container">
                        <div 
                          id="card-item-container-id">
                          <div id="action-items-title">
                            <span>Action Items</span>
                            <i className="far fa-window-minimize" onClick={() => this.closeActionItems()}></i>
                          </div>
                          {actionItems.map(item => (
                            <div 
                              className="card-item" 
                              key={item.objectId} 
                              style={{ paddingLeft: '10px', paddingRight: '10px' }}
                              onClick={() => this.showHideActionItemsModal(true)}>
                              <div title={item.text}>{item.text}</div>
                              <span 
                                className={item.status === 'PENDING' ? "badge badge-warning" : "badge badge-success"}>
                                {item.status.toLowerCase()}
                              </span>
                            </div>
                          ))}
                          {actionItems.length === 0 && (
                            <div className="em-container">
                              <em>No Actions Items Found</em>
                            </div>
                          )}
                        </div>
                      </div>
                      <button 
                        type="button" 
                        className="btn btn-primary btn-sm"
                        id="action-items-button"
                        onClick={() => this.openActionItems()}>Action Items</button>
                    </Fragment>
                  )} */}
                  </div>
                
                  <div id="cards-list-details">
                    <div className="cards-list-details-header">
                      <i
                        className="fal fa-chevron-left"
                        onClick={() => this.closeCardDetails(false)}
                        style={{ fontWeight: "bold" }}
                      />
                      <span>{detailsTitle}</span>
                    <div />
                  </div>
                  {showMedicinesDetails && (
                    <div className="card-details-container">
                      {patientMedications.map((medication) => {
                        if (medication.richText) {
                          return this.handleRichTextData(medication.richText);
                        }
                        if (medication.textArray) {
                          return this.handleRichTextArray(medication.textArray);
                        }
                        if (medication.free_text) {
                          return (
                            <div className="card-item" key={medication.free_text}>
                              <div title={medication.free_text}>
                                {medication.free_text || "No Name"}
                              </div>
                            </div>
                          );
                        }
                        return (
                          <div className="card-item" key={medication.displayName}>
                            <div title={medication.displayName}>
                              {medication.displayName || "No Name"}
                            </div>
                          </div>
                        );
                      })}
                      {medicationsLength === 0 && (
                        <div className="em-container">
                          <em>No Prescriptions Found</em>
                        </div>
                      )}
                      {patientMedicationsLoading && (
                        <div className="side-tab-empty">
                          <TabLoader isLoading={true} />
                        </div>
                      )}
                    </div>
                  )}
                  {showRxHistoryDetails && (
                    <div className="card-details-container">
                      {prescriptionsHistory.map((prescription) => {
                        const arr = prescription.medications.map((item) => {
                          if (item.free_text) return item.free_text;
                          else if (item.textArray) return item.textArray;
                          else return item.displayName;
                        });
                        const truncated = arr.join(",");
    
                        return (
                          <div
                            className="card-item"
                            key={prescription.objectId}
                            style={{ height: "40px" }}
                            onClick={() => {}}
                          >
                            <span style={{ minWidth: "85px" }}>
                              {moment(prescription.createdAt).format("l")}
                            </span>
                            <div>{`${truncated}` || "--"}</div>
                            <div className="copyOpen">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary btn-sm"
                                onClick={() =>
                                  this.copyPrescriptionLink(prescription.objectId)
                                }
                              >
                                Copy
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                className="btn btn-sm btn-primary btn-sm"
                                onClick={() =>
                                  this.openPrescriptionHistory(
                                    prescription.objectId
                                  )
                                }
                              >
                                Open
                              </button>
                            </div>
                          </div>
                        );
                      })}
                      {prescriptionsHistory.length === 0 && !rxLoading && (
                        <div className="em-container">
                          <em>No Prescription History Found</em>
                        </div>
                      )}
                      {rxLoading && (
                        <div className="side-tab-empty">
                          <TabLoader isLoading={true} />
                        </div>
                      )}
                    </div>
                  )}
                  {showOrdersHistoryDetails && (
                    <div className="card-details-container">
                      {patientOrdersHistory.map((order) => (
                        <div
                          className="card-item"
                          key={order.objectId}
                          style={{ height: "40px" }}
                          onClick={() => {}}
                        >
                          <span style={{ minWidth: "85px" }}>
                            {moment(order.createdAt).format("l")}
                          </span>
                          <div>
                            {`${order.diagnosticStudies}${order.referrals}${order.medicalSupplies}` ||
                              "--"}
                          </div>
                          <div className="copyOpen">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary btn-sm"
                              onClick={() => this.copyOrderLink(order.objectId)}
                            >
                              Copy
                            </button>
                            &nbsp;
                            <button
                              type="button"
                              className="btn btn-sm btn-primary btn-sm"
                              onClick={() => this.openOrderHistory(order.objectId)}
                            >
                              Open
                            </button>
                          </div>
                        </div>
                      ))}
                      {patientOrdersHistory.length === 0 && !ordersLoading && (
                        <div className="em-container">
                          <em>No Orders History Found</em>
                        </div>
                      )}
                      {ordersLoading && (
                        <div className="side-tab-empty">
                          <TabLoader isLoading={true} />
                        </div>
                      )}
                    </div>
                  )}
                  {showOrdersDetails && (
                    <div className="card-details-container">
                      {patientOrdersHistory.length === 0 && (
                        <div className="em-container">
                          <em>No Orders Found</em>
                        </div>
                      )}
                    </div>
                  )}
                  {showDiagnosesDetails && (
                    <div className="card-details-container">
                      {patientDiagnoses.map((diagnosis) => (
                        <div className="card-item" key={diagnosis.code}>
                          <span>{diagnosis.code}</span>
                          <div>{diagnosis.shortDescription}</div>
                        </div>
                      ))}
                      {patientDiagnoses.length === 0 && (
                        <div className="em-container">
                          <em>No Diagnoses Found</em>
                        </div>
                      )}
                      {patientDiagnosesLoading && (
                        <div className="side-tab-empty">
                          <TabLoader isLoading={true} />
                        </div>
                      )}
                    </div>
                  )}
                  {/* {showActionItemsDetails && (
                    <div className="card-details-container">
                      {actionItems.map((item) => (
                        <div className="card-item" key={item.objectId}>
                          <div title={item.text}>{item.text}</div>
                          <span className={item.status === "PENDING" ? "badge badge-warning" : "badge badge-success"}>
                            {item.status.toLowerCase()}
                          </span>
                        </div>
                      ))}
                      {actionItems.length === 0 && (
                        <div className="em-container">
                          <em>No Actions Items Found</em>
                        </div>
                      )}
                    </div>
                  )} */}
                  {showProblemsDetails && (
                    <div className="card-details-container">
                      {patientProblems.map((problem) => (
                        <div className="card-item" key={problem.problem}>
                          <span>{problem.problem}</span>
                        </div>
                      ))}
                      {patientProblems.length === 0 && (
                        <div className="em-container">
                          <em>No Problems Found</em>
                        </div>
                      )}
                      {patientProblemsLoading && (
                        <div className="side-tab-empty">
                          <TabLoader isLoading={true} />
                        </div>
                      )}
                    </div>
                  )}
                  {showGroupedListDetails && (
                    <div className="card-details-container">
                      {groupedLists.map((list) => (
                        <div className="card-item-grouped-list" key={list}>
                          <span>
                            <strong>{list.subject}</strong>
                          </span>
                          <span>{list.body}</span>
                        </div>
                      ))}
                      {groupedLists.length === 0 && !groupListLoading && (
                        <div className="em-container">
                          <em>No Lists Found</em>
                        </div>
                      )}
                      {groupListLoading && (
                        <div className="side-tab-empty">
                          <TabLoader isLoading={true} />
                        </div>
                      )}
                    </div>
                  )}
                  {showProgressNotesDetails && (
                    <div className="card-details-container">
                      {progressNotes.map((note) => (
                        <div
                          className="card-item"
                          key={note.objectId}
                          onClick={() => this.openProgressNote(note.objectId)}
                        >
                          <span style={{ minWidth: "85px" }}>
                            {moment(note.dateSeen).format("l")}
                          </span>
                          <div>{note.chiefComplaints.join(", ") || "--"}</div>
                        </div>
                      ))}
                      {progressNotes.length === 0 && !notesLoading && (
                        <div className="em-container">
                          <em>No Progress Notes Found</em>
                        </div>
                      )}
                      {notesLoading && (
                        <div className="side-tab-empty">
                          <TabLoader isLoading={true} />
                        </div>
                      )}
                    </div>
                  )}
                </div>  
              </>
              )}
            </div>
          </div>
        )}
        {actionItemsModalShown && (
          <ActionsModal
            activeTab={activeTab}
            thread={activeThread}
            threadId={activeThread.threadId}
            isOpen={actionItemsModalShown}
            onClose={() => this.showHideActionItemsModal(false)}
            refreshData={() => this.refreshData(activeThread)}
          />
        )}
        {rxModalShown && (
          <PrescriptionModal
            thread={activeThread}
            threadId={activeThread.threadId}
            isOpen={rxModalShown}
            onClose={() => this.showHideRxModal(false)}
            refreshData={() => this.refreshData(activeThread)}
            openDocuments={() => this.openFilesWindow(activeThread)}
          />
        )}
        {ordersModalShown && (
          <PatientOrdersModal
            threadId={activeThread.threadId}
            isOpen={ordersModalShown}
            onClose={() => this.showHideOrdersModal(false)}
            refreshData={() => this.refreshData(activeThread)}
          />
        )}
        {dxModalShown && (
          <PatientDiagnosisModal
            threadId={activeThread.threadId}
            isOpen={dxModalShown}
            onClose={() => this.showHideDxModal(false)}
            refreshData={() => this.refreshData(activeThread)}
          />
        )}
        {problemModalShown && (
          <PatientProblemsModal
            threadId={activeThread.threadId}
            isOpen={problemModalShown}
            onClose={() => this.showHideProblemModal(false)}
            refreshData={() => this.refreshData(activeThread)}
          />
        )}
        {listModalShown && (
          <ListModal
            threadId={activeThread.threadId}
            isOpen={listModalShown}
            onClose={() => this.showHideListModal(false)}
            refreshData={() => this.refreshData(activeThread)}
          />
        )}
        {forwardModalShown ? (
          <ForwardModal
            isOpen={forwardModalShown}
            modalClose={() => this.setState({ forwardModalShown: false })}
            message={messageToForward}
          />
        ) : (
          ""
        )}
        {progressNotesModalShown && (
          <ProgressNotesModal
            patientId={activeThread.threadId}
            isOpen={progressNotesModalShown}
            onClose={() => this.showHidePNModal(false)}
            refreshData={() => this.refreshData(activeThread)}
          />
        )}
        <UpdateConferenceModal
          conference={selectedConference}
          isOpen={updateNotesModalShown}
          isActiveCall={false}
          onClose={(isUpdated) => {
            this.handleSetUpdateNotesModal(isUpdated);
            this.setState({
              updateNotesModalShown: false,
            });
          }}
        />
        {sideTabLoading && (
          <div className="side-tab-empty">
            <TabLoader isLoading={true} />
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  activeTab: state.thread.activeTab,
  conferences: state.conference.patient_conferences,
  initial_conference: state.conference.initial_conference,
  progressNotes: state.notes.progressNotes,
  patientMedications: state.patient.patientMedications,
  patientProblems: state.patient.patientProblems,
  patientDiagnoses: state.patient.patientDiagnoses,
  rxHistory: state.notes.rxHistory,
  patientOrdersHistory: state.patient.patientOrdersHistory,
  prescriptionsHistory: state.patient.prescriptionsHistory,
  groupedLists: state.patient.groupedLists,
  activeSideNavTab: state.group.activeSideNavTab,
  notesLoading: state.notes.isLoading,
  rxLoading: state.patient.isPrescriptionsHistoryLoading,
  ordersLoading: state.patient.isOrdersHistoryLoading,
  groupListLoading: state.patient.isGroupedListsLoading,
  patientMedicationsLoading: state.patient.patientMedicationsLoading,
  patientProblemsLoading: state.patient.patientProblemsLoading,
  patientDiagnosesLoading: state.patient.patientDiagnosesLoading,
  patientInfoReduced: state.patient.patientInfoReduced,
  searchMessages: state.thread.searchMessages,
});

const Hoverlay = ({ children }) => {
  return <div className="hoverlay">{children}</div>;
};

export default connect(mapStateToProps, {
  getConferenceCallsById,
  getProgressNotes,
  fetchPatientMedications,
  fetchPatientProblems,
  fetchPatientDiagnoses,
  getPatientOrdersHistory,
  getPrescriptionsHistory,
  getGroupedLists,
  fetchSelectedThread,
})(SideTab);
