import React, { Component, Fragment } from "react";
import moment from "moment";
import Linkify from "react-linkify";
import ImageLoad from "../../Common/ImageLoad";
import ImagePlaceholder from "../../Common/ImagePlaceholder";
import Seen from "./Seen";
import DraggableBubble from "./DraggableBubble";
import { Document, Page, pdfjs, Outline } from "react-pdf";
import MMSMedia from "./MMSMedia";
import AttachmentContainer from "./AttachmentContainer";
import ReplyTo from "./ReplyTo";
import DOMPurify from "dompurify";
import parse from 'html-react-parser';
import { connect } from "react-redux";
import {
  setAttentionedMessage,
} from "../../../actions/ThreadAction";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const WaveSurfer = window.WaveSurfer;
export class FromThem extends Component {
  constructor() {
    super();
    this.wavesurfer = "";
    this.state = {
      isExpanded: false,
      showViewMore: false,
      attentioned_users: [],
    };
    this.highlightSpecificWords = this.highlightSpecificWords.bind(this);
    this.handleTextDisplay = this.handleTextDisplay.bind(this);
    this.handleViewMoreDisplay = this.handleViewMoreDisplay.bind(this);
  }

  getFileExtention(name) {
    return name.substr((~-name.lastIndexOf(".") >>> 0) + 2);
  }

  openLightbox(url) {
    this.props.openLightbox(url);
  }

  handleReply() {
    const { message } = this.props;
    // let time = moment(new Date(message.createdAt)).format('MM[/]D[/]YY HH[:]mm');
    // let reply = "\"Re: " + message.text + "\" -- " + message.user.firstName + " - " + time + "\n------------------\n\n";
    this.props.initReply(message);
  }

  handleReplySMS() {
    this.props.onReplySMS();
  }

  getPdfThumbnail(thumbnail) {
    if (!thumbnail) return "";

    const url = thumbnail.url;

    const ext = this.getFileExtention(url).toLowerCase();

    if (ext === "pdf") return "";

    return url;
  }

  highlightSpecificWords = ({ text, highlightWords }) => {
    const highlightText = (text, words) => {
      const escapedHighlightWords = words.map((word) => word.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"));
      const pattern = new RegExp(`(${escapedHighlightWords.join("|")})`, "gi");
      const parts = text.split(pattern);
      if (highlightWords.length > 0) {
        return parts.map((part, index) =>
          pattern.test(part) ? (
            <span key={index} style={{ color: "#2193f3", fontWeight: "bold" }}>
              {part}
            </span>
          ) : (
            part
          )
        );
      } else {
        return text;
      }
    };

    // const html_string = highlightText(text, highlightWords); // mobile attention highlight
    const html_string = text;
    const cleanHTML = DOMPurify.sanitize(html_string);
    // return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
    return parse(cleanHTML);
  };

  // not currently used
  handleTextDisplay(text = "", isViewMore) {
    const { isExpanded, attentioned_users } = this.state;
    const textLength = text.length;

    const lines = text.split(/\r\n|\r|\n/).length;
    const lineSlice = lines > 10 ? lines - 10 : 0;
    const linesArray = text.split(/\r?\n|\r/);
    const remainingLines = linesArray.slice(0, -lineSlice);
    const textSlice = remainingLines.join("\n");

    if (textLength >= 500) {
      return this.highlightSpecificWords({
          text: `${text.substr(0, 500)}...`,
          highlightWords: attentioned_users,
        });
    }
    return lines <= 10
      ? this.highlightSpecificWords({ text, highlightWords: attentioned_users })
      : this.highlightSpecificWords({
          // text: `${text.substr(0, 700)}...`,
          text: `${textSlice}...`,
          highlightWords: attentioned_users,
        });
  }

  handleViewMoreDisplay(bool) {
    this.setState({ isExpanded: bool });
  }

  handleGoToThread(threadId, message, objectId) {
    message.objectId = objectId;
    this.props.setAttentionedMessage(message);
    this.props.history.push({
      pathname: "/u/" + threadId,
    });
  }

  componentDidMount() {
    const { message, threadType = "group" } = this.props;
    if (message.attention && message.attention.length > 0 && threadType === "group") {
      const attentioned_users = message.attention.map((item) => `@${item.firstName}`);
      this.setState({ attentioned_users });
    }
  }

  render() {
    let { message, continuesMessage, currentUser, isCCM, isChecked, threadType = "private" } = this.props;

    const { isExpanded = false, attentioned_users } = this.state;
    if (!currentUser) return null;
    let containerStyle = {};
    let removeDisplay = {};
    const seenContainer = {
      fontSize: "12px",
      margin: "3px 3px 0px 8px",
      color: "rgba(0, 0, 0, .50)",
      fontWeight: "400",
      lineHeight: "12px",
      display: "flex",
      flexFlow: "wrap",
    };
    let themNameStyle = {
      paddingBottom: "10px",
      paddingLeft: "42px",
      display: "inherit",
      fontWeight: "bold",
      fontSize: "11px",
    };

    let Picture,
      Video,
      Audio,
      isPicture = false,
      isPDF = false,
      filename,
      Break = [],
      hasAudio = false,
      bubbleClass = "from-them",
      SeenArray = [],
      audioUrl,
      audioType;

    const title = message.user.firstName + " " + message.user.lastName;

    // Render attentioned users
    let attentioned;

    if (typeof message.attention !== "undefined") {
      for (let x = 0; x < message.attention.length; x++) {
        if (message.attention[x].objectId === currentUser.id) {
          bubbleClass = "from-them-attention";
          if (message.isUrgent) bubbleClass = "from-them-urgent";
          break;
        }
      }

      attentioned = message.attention
        .filter((user) => !message.text.includes(`@${user.firstName}`))
        .map((user) => {
          return "@" + user.firstName;
        })
        .join(", ");
    }

    if (message.forwardedToCCM) {
      bubbleClass = "from-them-forwarded";
    }
    /**
     * Check if message is from the same sender
     */
    if (continuesMessage) {
      containerStyle = {
        marginBottom: "5px",
      };
      removeDisplay = {
        display: "none",
      };
      themNameStyle.display = "none";
      if (bubbleClass === "from-them-attention") {
        bubbleClass = "from-them-attention-continues";
      } else if (bubbleClass === "from-them-urgent") {
        bubbleClass = "from-them-urgent-continues";
      } else if (bubbleClass === "from-them-forwarded") {
        bubbleClass = "from-them-forwarded-continues";
      } else {
        bubbleClass = "from-them-continues";
      }
    }

    /**
     * Avatar Iamge
     */
    let avatarImage;
    if (message.user) {
      if (!message.user.picture) {
        avatarImage = require("../../../assets/images/default.png");
      } else {
        avatarImage = message.user.picture.url;
      }
    } else {
      avatarImage = require("../../../assets/images/default.png");
    }

    /**
     * Check if picture object is pdf or picture
     */
    if (typeof message.picture !== "undefined") {
      if (typeof message.picture.url !== "undefined") {
        let ext;
        if (typeof message.picture.name === "undefined") {
          ext = this.getFileExtention(message.picture.file.name).toLowerCase();
        } else {
          ext = this.getFileExtention(message.picture.name).toLowerCase();
        }
        if (ext === "pdf") {
          isPDF = true;

          const thumbUrl = this.getPdfThumbnail(message.thumbnail);

          Picture = thumbUrl ? (
            <ImageLoad openLightbox={() => {}} imageURL={thumbUrl} />
          ) : (
            <ImagePlaceholder type="pdf" />
          );
        } else {
          isPicture = true;
          Picture = (
            <ImageLoad
              openLightbox={() => this.props.openLightbox(message.picture.url)}
              imageURL={message.picture.url}
            />
          );
        }
      }
    }

    const { smsFrom, mmsContentType } = message;
    /**
     * Check if video
     */
    if (typeof message.video != "undefined") {
      if (typeof message.video.url != "undefined") {
        Video = (
          <video key={1} style={{ marginBottom: "15px", display: "block", width: "100%" }} controls>
            <source src={message.video.url} />
            Your browser does not support HTML5 video.
          </video>
        );
      }
    }
    /**
     * Check if pdf
     */
    if (isPDF) {
      if (typeof message.filename !== "undefined") {
        filename = message.filename;
      } else {
        filename = message.picture.name;
      }

      if (message.text === "") {
        // Do nothing
      } else {
        if (typeof message.text !== "undefined") {
          Break.push(<br key={1} />);
          Break.push(<br key={2} />);
        }
      }
    }

    if (message.attachments && message.attachments.length) {
      if (message.attachments[0].file) {
        if (message.attachments[0].fileType) {
          if (message.attachments[0].fileType.match("image/.*") || message.attachments[0].fileType === "photo") {
            isPicture = true;
            Picture = (
              <ImageLoad
                openLightbox={this.openLightbox.bind(this, message.attachments[0].file.url)}
                imageURL={message.attachments[0].file.url}
              />
            );
          } else if (message.attachments[0].fileType.match("audio/.*")) {
            audioUrl = message.attachments[0].file.url;
            audioType = message.attachments[0].fileType;
            hasAudio = true;
          } else if (
            message.attachments[0].fileType.match("file/pdf") ||
            message.attachments[0].fileType.match("application/pdf")
          ) {
            // isPDF = true;
            // pdfURL = message.attachments[0].file.url;
            // filename = message.attachments[0].file.name;
            // Picture = <img key={1} src="../../images/pdf.png" alt={message.attachments[0].file.url} className="img-fluid" style={{display: 'inline-block', width: "20px", height: "20px",marginBottom: "15px"}} />;
          }
        }
      }
    }

    /**
     * Handle users seen
     */

    const seenBy = this.props.seenBy(message.objectId);
    for (let x = 0; x < seenBy.length; x++) {
      let url;
      if (typeof seenBy[x].picture === "undefined") {
        url = "../images/default.png";
      } else {
        url = seenBy[x].picture.url;
      }
      const name = seenBy[x].firstName + " " + seenBy[x].lastName;
      SeenArray.push(<Seen key={seenBy[x].objectId} url={url} name={name} />);
    }

    if (hasAudio) {
      Audio = (
        <audio controls style={{ display: "block", marginBottom: "10px", marginTop: "10px" }}>
          <source src={audioUrl} type={audioType} />
          Your browser does not support the audio element.
        </audio>
      );
    }

    const element = document.getElementById(`text_${message.objectId}`);
    const lineHeight = element ? parseFloat(getComputedStyle(element).lineHeight) : 0;
    const line = element ? element.offsetHeight / lineHeight : 0;
    // const moreLines = line >= 19;
    const moreLines = element ? element.offsetHeight >= 352 : false;

    // let lines = (message.text || "").split(/\r\n|\r|\n|<br\s*\/?>/).length;
    // let letters = message.text ? message.text.length : 0;
    
    let themDate = this.props.messageTimestamp(message.createdAt);

    // const {text=''} = message
    // const removedAttention = text.replace(/@.*?: /, '');
    const { replyTo, replyingTo, text } = message;

    return (
      <li className="Bubble-List-Container" style={containerStyle} id={`id_${message.objectId}`}>
        <div className="_them-container">
          <div className="_avatar-column">
            {this.props.showCheckBoxes && (
              <div className="acR acr-flex">
                <div className="round-small" onClick={() => this.props.handleCheckUncheckMessages(!isChecked)}>
                  <input type="checkbox" id="selectAll" name="selectAll" checked={isChecked} />
                  <label for="checkbox"></label>
                </div>
              </div>
            )}
            {!this.props.showCheckBoxes && (
              <Fragment>
                <div className="acR"></div>
                <div className="avatar">
                  <img style={removeDisplay} src={avatarImage} title={title} />
                </div>
              </Fragment>
            )}
          </div>
          <div className="_them-column" ref="target" style={{ position: "relative" }}>
            <div
              className={"bubble " + bubbleClass}
              style={{ cursor: "pointer", overflow: "hidden" }}
              // title={moment(message.createdAt).format("MMMM D, YYYY [at] LTS Z")}
              title={themDate}
            >
              {replyingTo && replyingTo.length ? (
                replyingTo.map((replyTo) => {
                  return <ReplyTo replyTo={replyTo} />;
                })
              ) : replyTo ? (
                <ReplyTo replyTo={replyTo} />
              ) : (
                ""
              )}
              {message.attachments && message.attachments.length ? (
                <AttachmentContainer attachments={message.attachments} onOpen={(url) => this.openLightbox(url)} />
              ) : (
                <div>
                  {isPDF ? (
                    <div onClick={() => window.open(message.picture.url, "_blank")} style={{ cursor: "pointer" }}>
                      {Picture}
                    </div>
                  ) : (
                    Picture
                  )}
                  {Audio}
                  {Video}
                  {isPDF ? (
                    <a
                      ref={(p) => (this.pdf = p)}
                      style={{ textDecoration: "underline", marginLeft: "3px" }}
                      href={message.picture.url}
                      target="_blank"
                    >
                      {filename}
                    </a>
                  ) : null}
                </div>
              )}
              {smsFrom && mmsContentType ? (
                <MMSMedia message={message} onClickImage={() => this.props.onClickMMS(message.mmsUrl)} />
              ) : (
                ""
              )}
              {/* {isPDF && <Document className="react-pdf__Page-canvas-container" file={message.picture.url}><Page pageNumber={1} /></Document>} */}
              {Break}
              {!hasAudio ? (
                <Linkify
                  properties={{
                    onClick(event) {
                      event.preventDefault();
                      window.open(event.target.href, "_blank", "noopener, noreferrer");
                    },
                  }}
                >
                  {attentioned ? (
                    <p className={message.isUrgent ? "urgent-users" : "attentioned-users"}>{attentioned}</p>
                  ) : (
                    ""
                  )}
                  <div className="bubble-style">
                      <span 
                        id={`text_${message.objectId}`} 
                        className={`message-text-content ${ !isExpanded ? "limited-text" : ""}`}
                      >
                        {this.highlightSpecificWords({ 
                          text: message.text,
                          highlightWords: attentioned_users,
                        })}
                      </span>
                    {message.originalMessageId && threadType === "private" && (
                      <span
                        className="text-primary badge badge-light"
                        style={{ padding: "4px 8px" }}
                        onClick={() => {
                          this.handleGoToThread(
                            message.originalThreadId,
                            message,
                            message.originalMessageId,
                          );
                        }}
                      >
                        Go to message
                      </span>
                    )}
                    {threadType === "group" && (
                      <div className="task-priority-cls">
                        {[0, 1, 2].includes(message.priorityLevel) && (
                          <Fragment>
                            {message.priorityLevel === 2 && (
                              <span className="badge badge-pill badge-light priority-flex">
                                <i className="fad fa-angle-double-up fa-fw" style={{ color: "#04589e" }}></i>
                                &nbsp;High Priority
                              </span>
                            )}
                            {message.priorityLevel === 1 && (
                              <Fragment>
                                <span className="badge badge-pill badge-light priority-flex">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#f6c23e",
                                    }}
                                  >
                                    =
                                  </span>
                                  &nbsp;
                                  <span> Medium Priority</span>
                                </span>
                              </Fragment>
                            )}
                            {/* {message.priorityLevel === 0 && (
                              <span className="badge badge-pill badge-light priority-flex">
                                <i className="text-primary fad fa-angle-double-down fa-fw"></i>
                                &nbsp; Routine Priority
                              </span>
                            )} */}
                          </Fragment>
                        )}
                        {message.actionItem && (
                          <div align="left">
                            <span
                              className="text-primary badge badge-light"
                              style={{ padding: "4px 8px" }}
                              onClick={() => {
                                this.props.openActionsModal(true, message.actionItem.objectId);
                                // window.open(
                                //   `${window.location.origin}/action-items/${message.threadId}?itemId=${message.actionItem.objectId}`,
                                //   "Hubchart Action Items"
                                // );
                              }}
                            >
                              View task
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {moreLines && (
                      <Fragment>
                        {!isExpanded && (
                          <div align="right">
                            <span
                              className="message-text-content"
                              style={{ color: "#2193f3", cursor: "pointer" }}
                              onClick={() => this.handleViewMoreDisplay(true)}
                            >
                              Show more
                            </span>
                          </div>
                        )}
                        {isExpanded && (
                          <div align="right">
                            <span
                              className="message-text-content"
                              style={{ color: "#2193f3", cursor: "pointer" }}
                              onClick={() => this.handleViewMoreDisplay(false)}
                            >
                              Show less
                            </span>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                </Linkify>
              ) : (
                ""
              )}
            </div>
            {!this.props.showCheckBoxes && (
              <div className="speech-menu-container dropdown">
                <button
                  type="button"
                  className="btn speech-menu dropdown-toggle"
                  style={{ padding: "0px", height: "28px" }}
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-h"></i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {this.props.fromSMS ? (
                    <a onClick={this.handleReplySMS.bind(this)} className="dropdown-item" href="#">
                      <i className="fas fa-sms mr-1"></i>
                      Reply via SMS
                    </a>
                  ) : (
                    <a onClick={this.handleReply.bind(this)} className="dropdown-item" href="#">
                      <i className="fas fa-reply mr-1"></i>
                      Reply
                    </a>
                  )}
                  <a onClick={() => this.props.openForward(message, "forward")} className="dropdown-item" href="#">
                    <i className="fas fa-share-square mr-1"></i>
                    Forward
                  </a>
                  <a onClick={() => this.props.openSelectMultiple(true)} className="dropdown-item" href="#">
                    <i className="fas fa-share-square mr-1"></i>
                    Forward multiple
                  </a>
                  <a
                    onClick={() => this.props.handleSetTaskAndMessage(true, message.text, true)}
                    className="dropdown-item"
                    href="#"
                  >
                    <i className="fas fa-tasks mr-1"></i>
                    Set as new Task
                  </a>
                  <a onClick={() => this.props.toBeDelete()} className="dropdown-item" href="#">
                    <i className="fas fa-trash mr-1"></i>
                    Delete
                  </a>
                  {isCCM && (
                    <a onClick={() => this.props.openForwardToCCM()} className="dropdown-item" href="#">
                      Forward To CCM
                    </a>
                  )}
                </div>
                <ul>{/* <li className="transform-btn" onClick={this.handleReply.bind(this)}>Reply</li> */}</ul>
                {threadType != "private" && (
                  <button
                    type="button"
                    className="btn speech-menu-2"
                    style={{ padding: "0px", height: "28px" }}
                    onClick={() => this.props.openReminder(message.text)}
                  >
                    <i className="fas fa-business-time"></i>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="them-name" style={themNameStyle}>
          <div>
            {message.edited && ("(edited) ")}
            {" "}
            <span>{themDate}</span> <span style={{ fontSize: "8px" }}>&#9679;</span> {message.user.firstName}{" "}
            {message.actionItem && (
              <span>
                <span style={{ fontSize: "8px" }}>&#9679;</span>{" "}
                <span
                  style={{ fontStyle: "bold" }}
                  className={message.actionItem.status === "DONE" ? "text-success font-bold" : "text-warning font-bold"}
                >
                  {message.actionItem.status} <i className="fas fa-check-circle"></i>
                </span>
              </span>
            )}
          </div>
        </div>
        <div style={seenContainer}>{SeenArray}</div>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  setAttentionedMessage,
})(FromThem);
