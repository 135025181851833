import moment from "moment";
const CryptoJS = require("crypto-js");
const $ = window.$;

export const beautifyTime = (date) => {
  let today = moment(new Date());
  let yesterday = moment().subtract(1, "day");
  let dateSend = moment(new Date(date));
  if (typeof date === "undefined") {
    return;
  }
  // TODAY
  if (moment(dateSend).isSame(today, "day")) {
    return moment(new Date(date)).format("h[:]mma");
  } else if (moment(dateSend).isSame(yesterday, "day")) {
    // YESTERDAY
    return moment(new Date(date)).format("ddd");
  } else if (moment(dateSend).isSame(today, "week")) {
    // SAME WEEK
    return moment(new Date(date)).format("ddd");
  } else if (moment(dateSend).isSame(today, "year")) {
    // SAME YEAR
    return moment(new Date(date)).format("MMM D");
  } else {
    return moment(new Date(date)).format("MMM D, YYYY");
  }
};

export const getFileExtension = (name) => {
  return name.substr((~-name.lastIndexOf(".") >>> 0) + 2);
};

export const getThreadId = (callerId = "", calleeId = "") => {
  const list = [callerId, calleeId];

  const sorted = list.sort((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  return sorted.join("_");
};

export const formatConferenceDate = (ISOString) => {
  const date = moment(ISOString);

  return {
    date: date.format("MMM DD"),
    time: date.format("h:mmA"),
  };
};

export const formatConferenceDuration = (timeStarted, timeEnded) => {
  if (!timeEnded) {
    return "00:00:00";
  }

  if (!timeStarted) {
    return "--:--:--";
  }

  const difference = moment(timeEnded).diff(moment(timeStarted));

  const duration = moment.duration(difference);

  const formatted = moment.utc(duration.as("milliseconds")).format("HH:mm:ss");

  return formatted;
};

export const uuid = () => {
  let d = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16).toUpperCase();
  });
  return uuid;
};

export const isIOS = () => {
  var iDevices = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"];

  if (navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }

  return false;
};

export const isMobile = {
  Windows: function() {
    return /IEMobile/i.test(navigator.userAgent);
  },
  Android: function() {
    return /Android/i.test(navigator.userAgent);
  },
  BlackBerry: function() {
    return /BlackBerry/i.test(navigator.userAgent);
  },
  Safari: function() {
    return /Safari/i.test(navigator.userAgent);
  },
  iOS: isIOS,
  any: function() {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows();
  },
};

export const fromThemDate = (date) => {
  let today = moment(new Date());
  let dateSend = moment(date);

  let themDate;

  if (moment(dateSend).isSame(today, "day")) {
    themDate = "Today - " + moment(date).format("LT");
  } else {
    themDate = moment(date).format("MMM D, YYYY");
  }

  return themDate;
};

export const getMonthYear = (date) => {
  return moment(date).format("MMM YYYY");
};

export const randomID = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4();
};

export const computeAge = (dob = "") => {
  if (!dob) return null;

  const dateArray = dob.split("/");

  // The year is the alst
  let year = dateArray[2];

  if (!year) return null;

  if (year.length === 2) {
    dateArray[2] = "19" + year;
  }

  const dateString = dateArray.join("/");

  return moment().diff(dateString, "years");
};

export const calculateAge = (dob = "") => {
  if (!dob) return "--";
  const dobYear = new Date(dob).getFullYear();
  const currentYear = new Date().getFullYear();
  const newDate = dobYear > currentYear ? new Date(dob).getFullYear() - 100 : new Date(dob);

  return `${dob} (${moment().diff(moment(newDate, "YYYY"), "years")} yo)`;
};

export const telemedUrl = "https://web.hubchart.net/redirect_conferencecall";

export const encrypt = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

export const decrypt = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

export const getIdPath = (recent) => {
  switch (recent.threadType) {
    case "private":
      return recent.partnerObjectId;
    case "group":
      return recent.subgroupSessionId || recent.threadId;
    case "adhoc":
      return recent.threadId;
    default:
      return recent.objectId;
  }
};

export const UUID = () => {
  let d = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16).toUpperCase();
  });
  return uuid;
};

export const handleMessageFormat = (data = []) => {
  let msg = "";
  for (let i = 0; i < data.length; i++) {
    if (data[i].richText) {
      msg = msg + formatRichText(data[i].richText);
    } else if (data[i].textArray) {
      msg = msg + formatTextArray(data[i].textArray);
    } else if (data[i].free_text) {
      msg = msg + `\n${i + 1}. ${data[i].free_text}\n`;
    } else {
      const rx = data[i];
      let newMsg = msg + `\n${i + 1}. ${rx.displayName} \n${rx.direction} ${rx.amount} ${rx.dosage_form}`;
      newMsg =
        newMsg +
        ` ${rx.frequency ? rx.frequency_obj.text : ""} ${
          rx.duration > 0 && rx.durationStr ? `for ${rx.duration} ${rx.durationStr}` : ""
        } \n`;
      //   newMsg =
      //     newMsg + ` ${rx.route} ${rx.frequency ? rx.frequency_obj : ""} \n`;
      msg = newMsg + `${rx.additionalDirections ? `${rx.additionalDirections}` : ""}\n`;
    }
  }

  function formatRichText(rx) {
    let rMsg = "";
    for (let i = 0; i < rx.blocks.length; i++) {
      const blk = rx.blocks[i];
      const isListItem =
        rx.blocks[i].type === "unordered-list-item" || rx.blocks[i].type === "ordered-list-item" ? true : false;
      if (isListItem) {
        rMsg = rMsg + `${blk.depth === 0 ? `\n${i + 1}. ` : ""}${blk.text ? `${blk.text}` : ""}\n`;
      } else {
        rMsg = rMsg + `${!(rx.blocks[i - 1] || {}).text ? `\n${i + 1}. ` : ""}${blk.text ? `${blk.text}\n` : ""}`;
      }
    }
    return rMsg;
  }

  function formatTextArray(data) {
    let tMsg = "";
    for (let i = 0; i < data.length; i++) {
      tMsg = tMsg + `${i + 1}. ` + data[i] + "\n\n";
    }

    return tMsg;
  }
  return msg;
};

export const handleOrderFormat = (data) => {
  let msg = "";

  if (data.prescription) {
    msg = msg + "PRESCRIPTIONS: \n" + data.prescription;
  }
  if (data.diagnosticStudies) {
    msg = msg + "\n\nDIAGNOSTIC STUDIES:\n\n" + data.diagnosticStudies;
  }
  if (data.referrals) {
    msg = msg + "\n\nREFERRALS:\n\n" + data.referrals;
  }

  if (data.medicalSupplies) {
    msg = msg + "\n\nMEDICAL SUPPLIES:\n\n" + data.medicalSupplies;
  }

  return msg;
};

export const getConferenceText = (conference = {}) => {
  const {
    timeStarted = {},
    timeEnded = {},
    createdAt,
    label = "",
    dob = "",
    comments = "",
    chiefComplaint = "",
    participants = [],
  } = conference;
  const createdDate = formatConferenceDate(createdAt);
  const duration = formatConferenceDuration(timeStarted.iso, timeEnded.iso);
  const participantNames = participants
    .map((participant) => {
      return participant.displayName;
    })
    .join(", ");

  return [
    "[Conference log]",
    "Patient/Topic: " + label,
    "Date of Birth:" + dob,
    "Date and Time: " + createdDate.date + " - " + createdDate.time,
    "Duration: " + duration,
    "Chief Complaint: " + chiefComplaint,
    "Notes: " + comments,
    "---",
    "Participants: " + participantNames,
  ].join("\n");
};

export const checkFormValidity = (id) => {
  const isValid = $(`#${id}`)[0].checkValidity();
  if (!isValid) {
    var forms = document.querySelectorAll(".needs-validation");
    Array.prototype.slice.call(forms).forEach(function(form) {
      form.classList.add("was-validated");
    });
  }

  return isValid;
};

export const getPdfThumbnail = (thumbnail) => {
  if (!thumbnail) return "";

  const url = thumbnail.url;

  const ext = getFileExtension(url).toLowerCase();

  if (ext === "pdf") return "";

  return url;
};

export const removeExtraLines = (data = "") => {
  return data.replace(/^\s*$(?:\r\n?|\n)/gm, `\n`);
};

export const removeAllExtraLines = (data = "") => {
  return data.replace(/^\s*$(?:\r\n?|\n)/gm, ``);
};

export const downloadFile = (url) => {
  if (!url) return;

  // Source: http://pixelscommander.com/en/javascript/javascript-file-download-ignore-content-type/

  //iOS devices do not support downloading. We have to inform user about this.
  if (/(iP)/g.test(navigator.userAgent)) {
    alert("Your device does not support files downloading. Please try again in desktop browser.");
    window.open(url, "_blank");
    return false;
  }

  //If in Chrome or Safari - download via virtual link click
  if (
    navigator.userAgent.toLowerCase().indexOf("chrome") > -1 ||
    navigator.userAgent.toLowerCase().indexOf("safari") > -1
  ) {
    //Creating new link node.
    var link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");

    if (link.download !== undefined) {
      //Set HTML5 download attribute. This will prevent file from opening if supported.
      var fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
      link.download = fileName;
    }

    //Dispatching click event.
    if (document.createEvent) {
      var e = document.createEvent("MouseEvents");
      e.initEvent("click", true, true);
      link.dispatchEvent(e);
      return true;
    }
  }

  // Force file download (whether supported by server).
  if (url.indexOf("?") === -1) {
    url += "?download";
  }

  window.open(url, "_blank");
  return true;
};

export const detectType = (type = "") => {
  const typeRegex = {
    image: /^(image)/i,
    video: /^(video)/i,
    audio: /^(audio)/i,
    pdf: /(pdf)$/i,
  };

  if (typeRegex.image.test(type)) return "image";
  if (typeRegex.video.test(type)) return "video";
  if (typeRegex.audio.test(type)) return "audio";
  if (typeRegex.pdf.test(type)) return "pdf";

  return "";
};

export const formatForwardDate = (iso = "") => {
  return moment(iso).format("MM/D/YYYY h:mm A");
};

export const generateVideoThumbnail = (file) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};

export default {
  beautifyTime,
  getFileExtension,
  isMobile,
  getThreadId,
  formatConferenceDate,
  formatConferenceDuration,
  uuid,
  isIOS,
  fromThemDate,
  getMonthYear,
  randomID,
  computeAge,
  telemedUrl,
  encrypt,
  decrypt,
  getIdPath,
  UUID,
  handleMessageFormat,
  handleOrderFormat,
  checkFormValidity,
  getPdfThumbnail,
  removeExtraLines,
  downloadFile,
  detectType,
  formatForwardDate,
  generateVideoThumbnail,
};
