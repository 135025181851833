import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomCalendar.css";

const CustomCalendar = ({ loadFilteredMessage, activeThread, handleCloseSearchBar, resetSearchInput }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [yearPicker, setYearPicker] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);

  const handleYearChange = (date) => {
    const newYear = date.getFullYear();
    setSelectedDate((prevDate) => {
      return new Date(newYear, prevDate.getMonth(), prevDate.getDate());
    });
    setYearPicker(false);
    setIsOpen(true);
  };

  useEffect(() => {
    setSelectedDate(null);
    setYearPicker(false);
  }, [activeThread]);

  // useEffect(() => {
  //   console.log("Updated selectedDate:", selectedDate);
  // }, [selectedDate]);

  return (
    <div className="d-flex">
      <span className="d-flex">
        <i
          className="fal fa-calendar"
          style={{ color: "#2194f3", cursor: "pointer", fontSize: "22px", marginTop: "2px", marginRight: "5px" }}
          onClick={() => {
            datePickerRef.current.setOpen(true);
            setIsOpen(true);
          }}
        ></i>
        <DatePicker
          // todayButton={"Today"}
          dateFormat="MMMM d, yyyy"
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          scrollableMonthYearDropdown
          readOnly
          ref={datePickerRef}
          selected={selectedDate}
          formatWeekDay={(day) => day.toUpperCase().slice(0, 3)}
          showYearPicker={yearPicker}
          maxDate={new Date()}
          open={isOpen}
          onClickOutside={() => {
            setIsOpen(false);
            datePickerRef.current.setOpen(true);
          }}
          // onSelect={() => setIsOpen(true)}
          onChange={(date) => {
            if (yearPicker) {
              handleYearChange(date);
            } else {
              setSelectedDate(date);
              loadFilteredMessage({ date: date });
              setIsOpen(false);
            }
          }}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="flex justify-between px-3 py-2">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                className="p-1 rounded"
                style={{ float: "left" }}
              >
                <i className="fas fa-chevron-left" style={{ fontSize: "14px" }}></i>
              </button>
              <span
                className="font-semibold"
                style={{ cursor: "pointer" }}
                // onClick={() => setYearPicker(!yearPicker)} // Switch mode on click
              >
                {/* {date.toLocaleString("default", { month: "long" })} {date.getFullYear()} */}

                <select
                  style={{ border: "0px" }}
                  value={date.getMonth()}
                  onChange={(e) => changeMonth(parseInt(e.target.value))}
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={i}>
                      {new Date(0, i).toLocaleString("default", { month: "long" })}
                    </option>
                  ))}
                </select>
                <select
                  style={{ border: "0px" }}
                  value={date.getFullYear()}
                  onChange={(e) => changeYear(parseInt(e.target.value))}
                >
                  {Array.from(
                    { length: 10 }, // Only show 10 years
                    (_, i) => new Date().getFullYear() - i // Show recent years (descending)
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </span>
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                className="p-1 rounded"
                style={{ float: "right" }}
              >
                <i className="fas fa-chevron-right" style={{ fontSize: "14px" }}></i>
              </button>
            </div>
          )}
          popperPlacement="left-start"
        />
      </span>
      <i
        className="fas fa-times fa-lg"
        style={{ color: "#2194f3", cursor: "pointer", margin: "7px 0px" }}
        onClick={() => {
          handleCloseSearchBar();
          resetSearchInput("");
          setSelectedDate(null);
        }}
      ></i>
    </div>
  );
};

export default CustomCalendar;
